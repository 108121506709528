import {AlertButton, IonAlert, IonItemDivider, IonItemGroup, IonLabel, IonList, IonListHeader} from '@ionic/react';
import React, {Component} from 'react';
import CalendarItemListItem from './CalendarItemListItem';
import {CalendarEvent} from "../../../data/calendar/domain/CalendarEvent";
import {CalendarData} from "../../../data/calendar/domain/CalendarData";

interface CalendarItemListProps {
  calendarData: CalendarData;
}

interface CalendarItemListState {
  showAlert: boolean;
  alertHeader: string;
  alertButtons: (AlertButton | string)[];
}

class CalendarItemList extends Component<CalendarItemListProps, CalendarItemListState> {
  constructor(props: CalendarItemListProps) {
    super(props);
    this.state = {
      showAlert: false,
      alertHeader: '',
      alertButtons: []
    }
  }

  render() {
    if (this.props.calendarData.days.length === 0) {
      return (
        <IonList>
          <IonListHeader>
            No calendar data Found
          </IonListHeader>
        </IonList>
      );
    }

    const handleShowAlert = (header: string, buttons: AlertButton[]) => {
      this.setState({
        showAlert: true,
        alertHeader: header,
        alertButtons: buttons
      });
    }
    return (
      <>
        <IonList>
          {this.props.calendarData.days.data.map((day, index: number) => (
            <IonItemGroup key={`group-${index}`} id={day.date}>
              <IonItemDivider sticky>
                <IonLabel className={"calendar-item-label"}>
                  {day.date}
                </IonLabel>
              </IonItemDivider>
              {day.events.data.map((event: CalendarEvent, sessionIndex: number) => (
                <CalendarItemListItem
                  onShowAlert={handleShowAlert}
                  key={`group-${index}-${sessionIndex}`}
                  calendarEvent={event}
                />
              ))}
            </IonItemGroup>
          ))}
        </IonList>
        <IonAlert
          isOpen={this.state.showAlert}
          header={this.state.alertHeader}
          buttons={this.state.alertButtons}
          onDidDismiss={() => this.setState({showAlert: false})}
        />
      </>
    );
  }
}

export default CalendarItemList;
