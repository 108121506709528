import React, {Component} from "react";
import {IonItem, IonLabel, IonSelect, IonSelectOption} from "@ionic/react";
import {FormValidator, ValidatedElement} from "../../util/FormValidator";
import "./FormInput.scss";
import Reference from "../../data/domain/Reference";

interface Props {
  label: string;
  header: string;
  required: boolean;
  value: string[] | undefined;
  options: Reference[]
  onChange: (newValue: string[]) => void;
  validator: FormValidator;
  disabled?: boolean;
}

interface State {
  inError: boolean;
  value: string[] | undefined;
}

export default class MultiSelectInput extends Component<Props, State> implements ValidatedElement {

  constructor(props: Props) {
    super(props);
    this.props.validator.registerValidatedElement(this);
    this.state = {
      inError: false,
      value: props.value
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.state.inError && this.props.disabled) {
      this.clearErrors();
    }
  }

  render() {
    const {label, onChange, value, options, required, disabled, header} = this.props;
    const {inError} = this.state;

    return (
      <div>
        <IonItem className={`input-item multi-select-input-item ${inError ? 'in-error' : ''}`}>
          <IonLabel position="floating" className={"label"} color="primary">{label}{required ? '*' : ''}</IonLabel>
          <IonSelect
            value={value}
            multiple
            disabled={disabled}
            interface={"popover"}
            interfaceOptions={{header: header}}
            onIonChange={e => {
              onChange(e.detail.value);
              this.setState({value: e.detail.value});
              this.clearErrors();
            }}>
            {options.map((o, index) => <IonSelectOption key={index} value={o.ref}>{o.name}</IonSelectOption>)}
          </IonSelect>
        </IonItem>
        {inError && <IonLabel position="fixed" className={"error-message"}>This field is required.</IonLabel>}
      </div>
    )
  }

  validate(): boolean {
    const inError = !this.props.disabled && this.props.required && !this.state.value;
    this.setState({inError: inError});
    return !inError;
  }

  clearErrors(): void {
    this.setState({inError: false});
  }
}
