import {OrganisationLocation} from "./OrganisationLocation";

export type OrganisationLocation = string

interface OrganisationLocationFilter {
  name: string;
}

const DEFAULT_FILTER = {name: ""};

export class OrganisationLocations {
  constructor(private _locations: OrganisationLocation[] = [], private filter: OrganisationLocationFilter = DEFAULT_FILTER) {
  }

  setNameFilter(filter: string): OrganisationLocations {
    this.filter = {...this.filter, name: filter};
    return this;
  }

  clearFilter() {
    this.filter = DEFAULT_FILTER;
    return this;
  }

  get data(): OrganisationLocation[] {
    return this._locations.filter((og: OrganisationLocation) => {
      const nameMatch = og.toLowerCase().indexOf(this.filter.name) !== -1;
      return nameMatch;
    });
  }

  get single(): OrganisationLocation {
    if (this.data.length === 1) {
      return this.data[0];
    } else {
      throw Error("Not one single result for location list: " + this.data.map(g => g).join(','))
    }
  }

  add(group: OrganisationLocation) { //TODO remove this method when BE gets created
    this._locations.push(group);
  }
}
