export const groupBy = (items: any, key: string) => items.reduce(
  (result: any, item: any) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

export const pad = (num: number, size: number) => {
  let tmp = num.toString();
  while (tmp.length < size) tmp = "0" + tmp;
  return tmp;
}

export const scrollToElement = (elementId: string, callCount: number = 0) => {
  const element = document.getElementById(elementId);
  if (element) {
    setTimeout(() => {
      element.scrollIntoView();
      element.scrollBy(0, -25);
    }, 100);
  } else if (callCount < 50) {
    setTimeout(() => {
      scrollToElement(elementId, callCount++);
    }, 100)
  }
}
