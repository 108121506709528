import {CalendarState} from "./calendar.state";
import {CalendarActions} from "./calendar.actions";

export const CalendarReducer = (state: CalendarState, action: CalendarActions): CalendarState => {
  switch (action.type) {
    case 'set-calendar-loading': {
      return {...state, isCalendarLoading: action.isLoading};
    }
    case 'set-event-loading': {
      return {...state, isEventLoading: action.isLoading};
    }
    case 'set-calendar-data': {
      return {...state, ...action.data};
    }
  }
}
