import {ActionType} from '../../util/types';
import {
  getOrganisation,
  getOrganisationGroup,
  getOrganisationGroupMember,
  getOrganisationMember,
  getOrganisations,
  mockAddOrganisationGroupMemberToGroup,
  mockChangeIsResponsibleStateOfMemberOnOrganisationGroup,
  mockCreateOrganisationBoardRole,
  mockCreateOrganisationGroup, mockCreateOrganisationGroupRole, mockCreateOrganisationLocation,
  mockEditOrganisationGroupMember,
  mockEditOrganisationMember,
  mockRemoveOrganisationGroupMemberFromGroup
} from "./organisations.api";
import {OrganisationsState} from "./organisations.state";
import logger from "../../util/logger";
import {OrganisationGroupId} from "./domain/OrganisationGroup";
import {OrganisationId} from "./domain/Organisation";
import {OrganisationMemberId} from "./domain/OrganisationMember";
import {OrganisationMemberRoles} from "./domain/OrganisationMemberRole";
import {OrganisationGroupMemberId} from "./domain/OrganisationGroupMember";
import {OrganisationGroupMemberRoles} from "./domain/OrganisationGroupMemberRole";

export interface CreateOrganisationGroupRequest {
  organisationId: OrganisationId;
  groupName: string;
  responsibles: OrganisationMemberId[];
}

export interface CreateOrganisationBoardRoleRequest {
  organisationId: OrganisationId;
  boardRoleName: string;
}

export interface CreateOrganisationLocationRequest {
  organisationId: OrganisationId;
  locationName: string;
}

export interface CreateOrganisationGroupRoleRequest {
  organisationId: OrganisationId;
  organisationGroupId: OrganisationGroupId;
  groupRoleName: string;
}

export interface EditOrganisationMemberRequest {
  organisationId: OrganisationId;
  organisationMemberId: OrganisationMemberId;
  isBoardMember: boolean;
  roles: OrganisationMemberRoles;
}

export interface EditOrganisationGroupMemberRequest {
  organisationId: OrganisationId;
  organisationGroupId: OrganisationGroupId;
  organisationGroupMemberId: OrganisationGroupMemberId;
  isResponsible: boolean;
  roles: OrganisationGroupMemberRoles;
}

export interface AddAsMemberToOrganisationGroupRequest {
  organisationId: OrganisationId;
  organisationGroupId: OrganisationGroupId;
  organisationMemberId: OrganisationGroupMemberId;
}

export interface ChangeIsResponsibleStateOfMemberOnOrganisationGroupRequest {
  organisationId: OrganisationId;
  organisationGroupId: OrganisationGroupId;
  organisationMemberId: OrganisationGroupMemberId;
  isResponsible: boolean;
}

export interface RemoveAsMemberFromOrganisationGroup {
  organisationId: OrganisationId;
  organisationGroupId: OrganisationGroupId;
  organisationMemberId: OrganisationMemberId;
}

export const loadUserOrganisations = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setOrganisationsLoading(true));
  const data = await getOrganisations();
  logger.debug("got organisations", data);
  dispatch(setOrganisationsData(data));
  dispatch(setOrganisationsLoading(false));
}

export const loadUserOrganisation = (organisationId: OrganisationId, onNotFound: () => void) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setOrganisationLoading(true));
  const onFound = (data: OrganisationsState) => {
    logger.debug("got organisation", data);
    dispatch(setOrganisationsData(data));
    dispatch(setOrganisationLoading(false));
  }
  const internalOnNotFound = () => {
    dispatch(setOrganisationLoading(false));
    onNotFound();
  }
  await getOrganisation(organisationId, onFound, internalOnNotFound);
}

export const loadUserOrganisationGroup = (organisationId: OrganisationId, organisationGroupId: OrganisationGroupId, onNotFound: () => void) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setOrganisationGroupLoading(true));
  const onFound = (data: OrganisationsState) => {
    logger.debug("got organisation group", data);
    dispatch(setOrganisationsData(data));
    dispatch(setOrganisationGroupLoading(false));
  }
  const internalOnNotFound = () => {
    dispatch(setOrganisationGroupLoading(false));
    onNotFound();
  }
  await getOrganisationGroup(organisationId, organisationGroupId, onFound, internalOnNotFound);
}

export const loadOrganisationMember = (organisationId: OrganisationId, organisationMemberId: OrganisationMemberId) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setOrganisationMemberLoading(true));
  const data = await getOrganisationMember(organisationId, organisationMemberId);
  logger.debug("got organisation member", data);
  dispatch(setOrganisationsData(data));
  dispatch(setOrganisationMemberLoading(false));
}

export const loadOrganisationGroupMember = (organisationId: OrganisationId, organisationGroupId: OrganisationGroupId, organisationMemberId: OrganisationMemberId) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setOrganisationGroupMemberLoading(true));
  const data = await getOrganisationGroupMember(organisationId, organisationGroupId, organisationMemberId);
  logger.debug("got organisation group member", data);
  dispatch(setOrganisationsData(data));
  dispatch(setOrganisationGroupMemberLoading(false));
}

export const createOrganisationGroup = (request: CreateOrganisationGroupRequest, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  await mockCreateOrganisationGroup(request).then(onSuccess).catch(onError);
  logger.debug("organisation group created", request);
}

export const createOrganisationBoardRole = (request: CreateOrganisationBoardRoleRequest, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  await mockCreateOrganisationBoardRole(request).then(onSuccess).catch(onError);
  logger.debug("organisation board role created", request);
}

export const createOrganisationLocation = (request: CreateOrganisationLocationRequest, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  await mockCreateOrganisationLocation(request).then(onSuccess).catch(onError);
  logger.debug("organisation location created", request);
}

export const createOrganisationGroupRole = (request: CreateOrganisationGroupRoleRequest, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  await mockCreateOrganisationGroupRole(request).then(onSuccess).catch(onError);
  logger.debug("organisation group role created", request);
}

export const editOrganisationMember = (request: EditOrganisationMemberRequest, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  try {
    await mockEditOrganisationMember(request).then(onSuccess).catch(onError);
    logger.debug("organisation member edited", request);
    onSuccess();
  } catch (e) {
    logger.error("editOrganisationMember error", e);
    onError(e);
  }
}

export const editOrganisationGroupMember = (request: EditOrganisationGroupMemberRequest, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  try {
    await mockEditOrganisationGroupMember(request).then(onSuccess).catch(onError);
    logger.debug("organisation group member edited", request);
    onSuccess();
  } catch (e) {
    logger.error("editOrganisationGroupMember error", e);
    onError(e);
  }
}

export const addAsMemberToOrganisationGroup = (request: AddAsMemberToOrganisationGroupRequest, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  try {
    dispatch(setOrganisationMemberLoading(true));
    dispatch(setOrganisationLoading(true));
    dispatch(setOrganisationGroupLoading(true));
    const data = await mockAddOrganisationGroupMemberToGroup(request);
    logger.debug("organisation member added to group", request);
    dispatch(setOrganisationsData(data));
    onSuccess();
    dispatch(setOrganisationMemberLoading(false));
    dispatch(setOrganisationLoading(false));
    dispatch(setOrganisationGroupLoading(false));
  } catch (e) {
    logger.error("addAsMemberToOrganisationGroup error", e);
    onError(e);
    dispatch(setOrganisationMemberLoading(false));
    dispatch(setOrganisationLoading(false));
    dispatch(setOrganisationGroupLoading(false));
  }
}

export const removeAsMemberFromOrganisationGroup = (request: RemoveAsMemberFromOrganisationGroup, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  try {
    dispatch(setOrganisationMemberLoading(true));
    dispatch(setOrganisationLoading(true));
    dispatch(setOrganisationGroupLoading(true));
    const data = await mockRemoveOrganisationGroupMemberFromGroup(request);
    logger.debug("organisation member removed from group", request);
    dispatch(setOrganisationsData(data));
    onSuccess();
    dispatch(setOrganisationMemberLoading(false));
    dispatch(setOrganisationLoading(false));
    dispatch(setOrganisationGroupLoading(false));
  } catch (e) {
    logger.error("removeAsMemberFromOrganisationGroup error", e);
    onError(e);
    dispatch(setOrganisationMemberLoading(false));
    dispatch(setOrganisationLoading(false));
    dispatch(setOrganisationGroupLoading(false));
  }
}

export const changeIsResponsibleStateOfMemberOnOrganisationGroup = (request: ChangeIsResponsibleStateOfMemberOnOrganisationGroupRequest, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  try {
    dispatch(setOrganisationMemberLoading(true));
    dispatch(setOrganisationLoading(true));
    dispatch(setOrganisationGroupLoading(true));
    const data = await mockChangeIsResponsibleStateOfMemberOnOrganisationGroup(request);
    logger.debug("organisation member is responsible to group - change success?", request);
    dispatch(setOrganisationsData(data));
    onSuccess();
    dispatch(setOrganisationMemberLoading(false));
    dispatch(setOrganisationLoading(false));
    dispatch(setOrganisationGroupLoading(false));
  } catch (e) {
    logger.error("changeIsResponsibleStateOfMemberOnOrganisationGroup error", e);
    onError(e);
    dispatch(setOrganisationMemberLoading(false));
    dispatch(setOrganisationLoading(false));
    dispatch(setOrganisationGroupLoading(false));
  }
}

export const setOrganisationsLoading = (isLoading: boolean) => ({
  type: 'set-organisations-loading',
  isLoading
} as const);

export const setOrganisationLoading = (isLoading: boolean) => ({
  type: 'set-organisation-loading',
  isLoading
} as const);

export const setOrganisationGroupLoading = (isLoading: boolean) => ({
  type: 'set-organisation-group-loading',
  isLoading
} as const);

export const setOrganisationMemberLoading = (isLoading: boolean) => ({
  type: 'set-organisation-member-loading',
  isLoading
} as const);

export const setOrganisationGroupMemberLoading = (isLoading: boolean) => ({
  type: 'set-organisation-group-member-loading',
  isLoading
} as const);

export const setOrganisationsData = (data: Partial<OrganisationsState>) => ({
  type: 'set-organisations-data',
  data
} as const);

export type OrganisationsActions =
  | ActionType<typeof setOrganisationsLoading>
  | ActionType<typeof setOrganisationLoading>
  | ActionType<typeof setOrganisationGroupLoading>
  | ActionType<typeof setOrganisationMemberLoading>
  | ActionType<typeof setOrganisationGroupMemberLoading>
  | ActionType<typeof setOrganisationsData>
