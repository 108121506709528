import React, {Component} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';

import {RouteComponentProps} from "react-router";

import {connect} from "../../data/connect";
import logger from "../../util/logger";
import DataContainer from "../components/DataContainer";
import {editOrganisationMember, EditOrganisationMemberRequest, loadOrganisationMember} from "../../data/organisations/organisations.actions";
import {OrganisationMemberRoles} from "../../data/organisations/domain/OrganisationMemberRole";
import {OrganisationMember} from "../../data/organisations/domain/OrganisationMember";
import {Organisation} from "../../data/organisations/domain/Organisation";

interface SaveOrganisationMemberProps extends RouteComponentProps<{ organisationId: string, organisationMemberId?: string }, {}, { organisationName?: string }> {
  editOrganisationMember: typeof editOrganisationMember;
  loadOrganisationMember: typeof loadOrganisationMember;
  organisation?: Organisation;
  organisationMember?: OrganisationMember;
  isOrganisationMemberLoading: boolean;
}

interface SaveOrganisationMemberState {
  dataLoaded: boolean;
  loadTriggered: boolean;
  isLoading: boolean;
  title: string;
  submitButtonText: string;
  organisationName?: string;
  form: {
    isBoardMember?: boolean,
    memberRoles?: OrganisationMemberRoles
  }
}

class SaveOrganisationMember extends Component<SaveOrganisationMemberProps, SaveOrganisationMemberState> {

  constructor(props: SaveOrganisationMemberProps) {
    super(props);
    this.state = {
      dataLoaded: false,
      loadTriggered: false,
      isLoading: false,
      title: "",
      submitButtonText: "",
      organisationName: undefined,
      form: {
        isBoardMember: undefined,
        memberRoles: undefined
      }
    }
  }

  ionViewWillEnter() {
    const organisationId = this.props.match.params.organisationId;
    if (this.state.loadTriggered) {
      logger.info("save organisation member already visible");
    } else {
      logger.info("show save organisation member", organisationId);
      const organisationMemberId = this.props.match.params.organisationMemberId;
      const isEdit = organisationMemberId !== undefined;
      let title: string, submitButtonText: string;
      if (isEdit) {
        title = `Update organisation member`;
        submitButtonText = 'SAVE';
        this.props.loadOrganisationMember(organisationId, organisationMemberId!!);
      } else { //create
        const organisationName = this.props.location.state.organisationName;
        if (!organisationName) {
          console.error('organisationName is not filled');
          this.setState({...this.state, loadTriggered: false});
          this.props.history.push(`/organisations/${organisationId}`);
        } else {
          title = `Create new organisation group for organisation ${organisationName}`;
          submitButtonText = 'CREATE';
        }
      }
      this.setState({
        ...this.state,
        dataLoaded: false,
        loadTriggered: true,
        isLoading: false,
        title: title!!,
        submitButtonText: submitButtonText!!
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<SaveOrganisationMemberProps>, prevState: Readonly<SaveOrganisationMemberState>, snapshot?: any) {
    const member = this.props.organisationMember;
    if (!this.state.dataLoaded && member !== undefined) {
      this.setState({
        ...this.state,
        dataLoaded: true,
        form: {
          isBoardMember: member.isBoardMember,
          memberRoles: new OrganisationMemberRoles(member.boardRoles.data)
        }
      });
    }
  }

  ionViewWillLeave() {
    logger.info("hide save organisation");
    this.setState({loadTriggered: false});
  }

  render() {
    return (
      <IonPage id="save-organisation-group-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton/>
            </IonButtons>
            <IonTitle>{this.state.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.renderContent(this.isLoading() || !this.state.dataLoaded)}
        </IonContent>
      </IonPage>
    )
  }

  private isLoading() {
    return this.state.isLoading || this.props.isOrganisationMemberLoading || this.props.organisationMember === undefined || this.props.organisation === undefined;
  }

  renderContent(isLoading: boolean) {
    const editMember = () => {
      const request: EditOrganisationMemberRequest = {
        organisationId: this.props.match.params.organisationId,
        organisationMemberId: this.props.match.params.organisationMemberId!!,
        isBoardMember: this.state.form.isBoardMember || false,
        roles: this.state.form.memberRoles!!
      };
      this.props.editOrganisationMember(request,
        () => {
          logger.info("organisation member edited", request);
          this.props.history.push(`/organisations/${request.organisationId}`);
        },
        (reason: any) => {
          logger.error("something went wrong while editing organisation member", reason);
          //TODO error handling
        });
    }

    return (
      <DataContainer
        isLoading={isLoading}
        contentRender={() => {
          return (
            <div className="content-container">
              <IonList>
                <IonListHeader>Basic info</IonListHeader>
                <IonItem>
                  <IonLabel position="floating" color="primary">Organisation name</IonLabel>
                  <IonInput value={this.props.organisation!!.name} disabled>&nbsp;</IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating" color="primary">Member name</IonLabel>
                  <IonInput value={this.props.organisationMember!!.name} disabled>&nbsp;</IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel color="primary">Is board member?</IonLabel>
                  <IonToggle checked={this.state.form.isBoardMember}
                             onIonChange={e => {
                               this.setState({...this.state, form: {...this.state.form, isBoardMember: e.detail.checked, memberRoles: new OrganisationMemberRoles([])}})
                             }}/>
                </IonItem>
              </IonList>
              <IonList>
                <IonListHeader>Roles</IonListHeader>
                {this.props.organisation!!.availableMemberRoles.data.map(amr => {
                  return (
                    <IonItem key={amr.id}>
                      <IonLabel color="primary">{amr.name}</IonLabel>
                      <IonCheckbox key={amr.id}
                                   slot="end"
                                   color="primary"
                                   disabled={!this.state.form.isBoardMember}
                                   checked={this.state.form.memberRoles!!.containsId(amr.id)}
                                   onIonChange={e => this.changeRoleState(e.detail.checked, amr.id)}
                      >&nbsp;</IonCheckbox>
                    </IonItem>
                  )
                })}
              </IonList>
              <IonRow>
                <IonCol>
                  <IonButton type="submit" expand="block" onClick={editMember}>{this.state.submitButtonText}</IonButton>
                </IonCol>
              </IonRow>
            </div>
          )
        }}
      />
    )
  }

  private changeRoleState(isChecked: boolean, roleId: string) {
    const memberRoles = this.state.form.memberRoles!!;
    const availableMemberRoles = this.props.organisation!!.availableMemberRoles;

    let newMemberRoles;
    if (isChecked && !memberRoles.containsId(roleId)) {
      newMemberRoles = memberRoles.data;
      newMemberRoles.push(availableMemberRoles.getById(roleId));
    } else if (!isChecked && memberRoles.containsId(roleId)) {
      const index = memberRoles.data.map(r => r.id).indexOf(roleId);
      newMemberRoles = memberRoles.data.slice(index, 1);
    }

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        memberRoles: new OrganisationMemberRoles(newMemberRoles)
      }
    })
  }

}

export default connect<SaveOrganisationMemberProps>({
  mapStateToProps: (state) => ({
    organisation: state.organisationsState.selectedOrganisation,
    organisationMember: state.organisationsState.selectedOrganisationMember,
    isOrganisationMemberLoading: state.organisationsState.isOrganisationMemberLoading
  }),
  mapDispatchToProps: {
    editOrganisationMember,
    loadOrganisationMember
  },
  component: withIonLifeCycle(SaveOrganisationMember)
})
