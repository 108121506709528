import React, {Component} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';

import {RouteComponentProps} from "react-router";

import {connect} from "../../data/connect";
import Logger from "../../util/logger";
import logger from "../../util/logger";
import DataContainer from "../components/DataContainer";
import {createOrganisationBoardRole, CreateOrganisationBoardRoleRequest} from "../../data/organisations/organisations.actions";

interface SaveBoardRoleProps extends RouteComponentProps<{ organisationId: string, boardRoleId?: string }, {}, { organisationName?: string }> {
  createOrganisationBoardRole: typeof createOrganisationBoardRole;
}

interface SaveBoardRoleState {
  loadTriggered: boolean;
  isLoading: boolean;
  title: string;
  organisationName?: string;
  form: {
    boardRoleName?: string
  }
}

class SaveBoardRole extends Component<SaveBoardRoleProps, SaveBoardRoleState> {

  constructor(props: SaveBoardRoleProps) {
    super(props);
    this.state = {
      loadTriggered: false,
      isLoading: false,
      title: "",
      organisationName: undefined,
      form: {}
    }
  }

  ionViewWillEnter() {
    const organisationId = this.props.match.params.organisationId;
    if (this.state.loadTriggered) {
      Logger.info("save organisation board role already visible");
    } else {
      Logger.info("show save organisation board role", organisationId);
      const isEdit = this.props.match.params.boardRoleId !== undefined;
      let title, organisationName;
      if (isEdit) {
        title = `Organisation Detail`;
      } else { //create
        const organisationName = this.props.location.state.organisationName;
        if (!organisationName) {
          console.error('organisationName is not filled');
          this.setState({loadTriggered: false});
          this.props.history.push(`/organisations/${organisationId}`);
        } else {
          title = `Create new organisation board role for organisation ${organisationName}`;
        }
      }
      this.setState({
        loadTriggered: true,
        isLoading: false,
        title: title || "",
        organisationName: organisationName
      })
    }
  }

  ionViewWillLeave() {
    Logger.info("hide save organisation board role");
    this.setState({loadTriggered: false});
  }

  render() {
    return (
      <IonPage id="save-organisation-board-role-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton/>
            </IonButtons>
            <IonTitle>{this.state.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.renderContent(this.state.isLoading)}
        </IonContent>
      </IonPage>
    )
  }

  renderContent(isLoading: boolean) {
    const createBoardRole = () => {
      const request: CreateOrganisationBoardRoleRequest = {
        boardRoleName: this.state.form.boardRoleName || "", //TODO validation
        organisationId: this.props.match.params.organisationId
      };
      this.props.createOrganisationBoardRole(request,
        () => {
          logger.info("organisation board role created", request);
          this.props.history.push(`/organisations/${request.organisationId}`);
        },
        (reason: any) => {
          logger.error("something went wrong while creating board role", reason);
          //TODO error handling
        });
    }
    return (
      <DataContainer
        isLoading={isLoading}
        contentRender={() => {
          return (
            <div className="content-container">
              <IonList>
                <IonItem>
                  <IonLabel position="floating" color="primary">Board role name</IonLabel>
                  <IonInput value={this.state.form.boardRoleName}
                            onIonChange={e => this.setState({form: {...this.state.form, boardRoleName: e.detail.value || undefined}})}>&nbsp;</IonInput>
                </IonItem>
              </IonList>
              <IonRow>
                <IonCol>
                  <IonButton type="submit" expand="block" onClick={createBoardRole}>Create</IonButton>
                </IonCol>
              </IonRow>
            </div>
          )
        }}
      />
    )
  }

}

export default connect<SaveBoardRoleProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    createOrganisationBoardRole
  },
  component: withIonLifeCycle(SaveBoardRole)
})
