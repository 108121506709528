import {CalendarState} from "./calendar.state";
import {StaticCalenderData} from "./staticdata/calendardata";
import {SaveEventRequest} from "./calendar.actions";
import {CalendarDay} from "./domain/CalendarDay";
import {CalendarEvent, CalendarEventId} from "./domain/CalendarEvent";
import ViaeDateUtils from "../../util/ViaeDate.utils";
import logger from "../../util/logger";
import {pad} from "../../util/functions";

export const getCalendar = async (onFound: (state: CalendarState) => void, onNotFound: () => void) => {
  const calendarData = StaticCalenderData.DEFAULT;
  if (calendarData !== undefined) {
    const today = ViaeDateUtils.nowAsBackendFormattedString();
    if (calendarData.calendar && !calendarData.calendar.hasDayDataFor(today)) {
      logger.info("add empty calendar day for today", today);
      calendarData.calendar.addDay(new CalendarDay(today, []))
    }
    setTimeout(() => {
      onFound(calendarData!!);
    }, 1000);
  } else {
    onNotFound()
  }
}
export const getEvent = async (eventId: CalendarEventId, onFound: (state: CalendarState) => void, onNotFound: () => void) => {
  const calendarData = StaticCalenderData.DEFAULT;
  if (calendarData !== undefined) {
    let resultingEvent = undefined;
    if (calendarData.calendar !== undefined) {
      resultingEvent = calendarData.calendar.getEventById(eventId);
      calendarData.selectedEvent = resultingEvent;
    }
    if(resultingEvent){
      setTimeout(() => {
        onFound(calendarData!!);
      }, 1000);
    } else {
      onNotFound();
    }
  } else {
    onNotFound();
  }
}

export const mockSaveEvent = async (request: SaveEventRequest) => {
  let start, end;
  const startDate = new Date(request.start);
  const endDate = request.end ? new Date(request.end) : undefined;
  const date = ViaeDateUtils.toBackendFormattedString(startDate);
  if (!request.isRecurrentEvent) {
    if (request.isAllDayEvent) {
      start = "00:00";
      end = "23:59";
    } else {
      start = `${startDate.getHours()}:${startDate.getMinutes()}`;
      if (endDate) {
        end = `${endDate.getHours()}:${endDate.getMinutes()}`;
      }
    }
    const calendarDay: CalendarDay = getOrCreateCalendarDay(date)!!;
    StaticCalenderData.calendarItemCounter = StaticCalenderData.calendarItemCounter + 1;
    const event = new CalendarEvent(
      request.eventId || (StaticCalenderData.calendarItemCounter).toString(),
      date,
      endDate ? ViaeDateUtils.toBackendFormattedString(endDate) : date,
      start,
      end,
      request.name,
      request.location,
      request.organisation,
      request.organisationGroups,
      request.comments
    );
    if (request.eventId) {
      calendarDay.deleteEvent(request.eventId)
    }
    calendarDay.addEvent(event);
    logger.debug("event created", calendarDay.date, event, request.eventId);
  } else {
    for (let i = 0; i < (request.recurCount || 0); i++) {
      const tempDate = ViaeDateUtils.toBackendFormattedString(ViaeDateUtils.addDays(startDate, (i * request.recurringDayFrequency!!)));
      const tempEndDate = endDate ? ViaeDateUtils.toBackendFormattedString(ViaeDateUtils.addDays(endDate, (i * request.recurringDayFrequency!!))) : undefined;
      if (request.isAllDayEvent) {
        start = "00:00";
        end = "23:59";
      } else {
        const tempStartDate = ViaeDateUtils.addDays(startDate, request.recurringDayFrequency!!);
        start = `${pad(tempStartDate.getHours(), 2)}:${pad(tempStartDate.getMinutes(), 2)}`;
        if (endDate) {
          const tempEndDateTime = ViaeDateUtils.addDays(endDate, request.recurringDayFrequency!!);
          end = `${pad(tempEndDateTime.getHours(), 2)}:${pad(tempEndDateTime.getMinutes(), 2)}`;
        }
      }
      const calendarDay: CalendarDay = getOrCreateCalendarDay(tempDate)!!;
      const event = new CalendarEvent(
        (calendarDay.events.length + 1).toString(),
        tempDate,
        tempEndDate ? tempEndDate : tempDate,
        start,
        end,
        request.name,
        request.location,
        request.organisation,
        request.organisationGroups,
        request.comments
      );
      if (request.eventId) {
        calendarDay.deleteEvent(request.eventId)
      }
      calendarDay.addEvent(event);
      logger.debug("event created", calendarDay.date, event, request.eventId);
    }
  }
}

function getOrCreateCalendarDay(date: string) {
  const calendar = StaticCalenderData.DEFAULT.calendar!!;
  if (!calendar.hasDayDataFor(date)) {
    calendar.addDay(new CalendarDay(date, []))
  }
  return calendar.getDay(date)
}
