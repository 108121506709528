import {Message, Messages} from "../../messages/messages";
import {OrganisationGroup, OrganisationGroups} from "./OrganisationGroup";
import {OrganisationMember, OrganisationMembers} from "./OrganisationMember";
import {OrganisationMemberRole, OrganisationMemberRoles} from "./OrganisationMemberRole";
import {OrganisationLocation, OrganisationLocations} from "./OrganisationLocation";
import Reference from "../../domain/Reference";

export class Organisations {
  private filter: string = "";

  constructor(private _organisations: Organisation[] = []) {
  }

  setFilter(filter: string): Organisations {
    this.filter = filter.toLowerCase();
    return this;
  }

  get data(): Organisation[] {
    return this._organisations.filter((a: Organisation) => a.name.toLowerCase().indexOf(this.filter) !== -1);
  }

  asReferences(): Reference[] {
    return this.data.map(o => new Reference(o.id, o.name));
  }

  byId(ref: any): Organisation{
    return this._organisations.filter(o => o.id === ref)[0];
  }
}

export type OrganisationId = string

export class Organisation {
  private _groups: OrganisationGroups;
  private _members: OrganisationMembers;
  private _messages: Messages;
  private _availableMemberRoles: OrganisationMemberRoles;
  private _locations: OrganisationLocations;

  constructor(
    private _id: OrganisationId,
    private _name: string,
    _groups: OrganisationGroup[] = [],
    _members: OrganisationMember[] = [],
    _messages: Message[] = [],
    _availableMemberRoles: OrganisationMemberRole[] = [],
    _locations: OrganisationLocation[] = []
  ) {
    this._groups = new OrganisationGroups(_groups);
    this._messages = new Messages(_messages);
    this._members = new OrganisationMembers(_members);
    this._availableMemberRoles = new OrganisationMemberRoles(_availableMemberRoles);
    this._locations = new OrganisationLocations(_locations);
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get groups(): OrganisationGroups {
    return this._groups;
  }

  get board(): OrganisationMembers {
    return this._members.setIsBoardFilter(true)
  }

  get members(): OrganisationMembers {
    return this._members.clearFilter()
  }

  get availableMemberRoles(): OrganisationMemberRoles {
    return this._availableMemberRoles;
  }

  get messages(): Messages {
    return this._messages;
  }

  get locations(): OrganisationLocations {
    return this._locations;
  }

  asReference(): Reference {
    return new Reference(this.id, this.name);
  }
}
