import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet, IonSplitPane} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

import Menu from './components/Menu';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import {connect} from './data/connect';
import {AppContextProvider} from './data/AppContext';
import {loadConfData} from './data/sessions/sessions.actions';
import {loadUserData, setIsLoggedIn, setUsername} from './data/user/user.actions';
import {Schedule} from "./models/Schedule";
import CreateEvent from "./pages/events/CreateEvent";
import OrganisationListing from "./pages/organisations/OrganisationListing";
import OrganisationDetail from "./pages/organisations/OrganisationDetail";
import OrganisationGroupDetail from "./pages/organisations/OrganisationGroupDetail";
import SaveOrganisationGroup from "./pages/organisations/SaveOrganisationGroup";
import SaveOrganisationMember from "./pages/organisations/SaveOrganisationMember";
import SaveOrganisationBoardRole from "./pages/organisations/SaveOrganisationBoardRole";
import OrganisationGroupEditMembers from "./pages/organisations/OrganisationGroupEditMembers";
import SaveOrganisationGroupMember from "./pages/organisations/SaveOrganisationGroupMember";
import SaveOrganisationGroupRole from "./pages/organisations/SaveOrganisationGroupRole";
import Calendar from "./pages/events/Calendar";
import SaveOrganisationLocation from "./pages/organisations/SaveOrganisationLocation";
import SaveEvent from "./pages/events/SaveEvent";
import EventDetail from "./pages/events/EventDetail";
import NotificationListing from "./pages/notifications/NotificationListing";

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected/>
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  schedule: Schedule;
}

interface DispatchProps {
  loadConfData: typeof loadConfData;
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}

interface IonicAppProps extends StateProps, DispatchProps {
}

const IonicApp: React.FC<IonicAppProps> = ({darkMode, schedule, setIsLoggedIn, setUsername, loadConfData, loadUserData}) => {

  useEffect(() => {
    loadUserData();
    loadConfData();
    // eslint-disable-next-line
  }, []);

  return (
    schedule.groups.length === 0 ? (
      <div></div>
    ) : (
      <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu/>
            <IonRouterOutlet id="main">
              {/*<Route path="/tabs" component={MainTabs} />*/}
              <Route path="/calendar" component={Calendar} exact strict/>
              <Route path="/calendar/events/new" component={SaveEvent} exact strict/>
              <Route path="/calendar/events/:eventId" component={EventDetail} exact strict/>
              <Route path="/calendar/events/:eventId/:action" component={SaveEvent} exact strict/>
              <Route path="/organisations" component={OrganisationListing} exact strict/>
              <Route path="/organisations/:organisationId" component={OrganisationDetail} exact strict/>
              <Route path="/organisations/:organisationId/groups/new" component={SaveOrganisationGroup} exact strict/>
              <Route path="/organisations/:organisationId/groups/:organisationGroupId" component={OrganisationGroupDetail} exact strict/>
              <Route path="/organisations/:organisationId/members/:organisationMemberId/edit" component={SaveOrganisationMember} exact strict/>
              <Route path="/organisations/:organisationId/board/:organisationMemberId/edit" component={SaveOrganisationMember} exact strict/>
              <Route path="/organisations/:organisationId/groups/:organisationGroupId/members/edit" component={OrganisationGroupEditMembers} exact strict/>
              <Route path="/organisations/:organisationId/groups/:organisationGroupId/responsibles/:organisationGroupMemberId/edit"
                     component={SaveOrganisationGroupMember} exact strict/>
              <Route path="/organisations/:organisationId/groups/:organisationGroupId/members/:organisationGroupMemberId/edit"
                     component={SaveOrganisationGroupMember} exact strict/>
              <Route path="/organisations/:organisationId/boardroles/new" component={SaveOrganisationBoardRole} exact strict/>
              <Route path="/organisations/:organisationId/locations/new" component={SaveOrganisationLocation} exact strict/>
              <Route path="/organisations/:organisationId/groups/:organisationGroupId/grouproles/new" component={SaveOrganisationGroupRole} exact strict/>
              <Route path="/notifications" component={NotificationListing} exact strict/>
              {/*<Route path="/account" component={Account} />*/}
              {/*<Route path="/login" component={Login} />*/}
              {/*<Route path="/signup" component={Signup} />*/}
              {/*<Route path="/support" component={Support} />*/}
              {/*<Route path="/tutorial" component={Tutorial} />*/}
              <Route path="/logout" render={() => {
                setIsLoggedIn(false);
                setUsername(undefined);
                return <Redirect to="/"/>
              }}/>
              <Route path="/" component={Calendar} exact/>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    )
  )
}

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    schedule: state.data.schedule
  }),
  mapDispatchToProps: {loadConfData, loadUserData, setIsLoggedIn, setUsername},
  component: IonicApp
});
