import React, {Component} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonTitle, IonToggle, IonToolbar, withIonLifeCycle} from '@ionic/react';

import {RouteComponentProps} from "react-router";

import "./OrganisationGroupEditMembers.scss"
import {
  addAsMemberToOrganisationGroup,
  changeIsResponsibleStateOfMemberOnOrganisationGroup,
  loadUserOrganisation,
  loadUserOrganisationGroup,
  removeAsMemberFromOrganisationGroup
} from "../../data/organisations/organisations.actions";
import {connect} from "../../data/connect";
import logger from "../../util/logger";
import DataContainer from "../components/DataContainer";
import {Organisation} from "../../data/organisations/domain/Organisation";
import ListingByAvatarTitleAndSubtitle from "../components/ListingByAvatarTitleAndSubtitle";
import ErrorMessage from "../components/ErrorMessage";
import {OrganisationGroup, OrganisationGroupId} from "../../data/organisations/domain/OrganisationGroup";
import {ToggleChangeEventDetail} from "@ionic/core";

interface OrganisationGroupEditMembersProps extends RouteComponentProps<{ organisationId: string, organisationGroupId: string }> {
  loadUserOrganisationGroup: typeof loadUserOrganisationGroup;
  loadUserOrganisation: typeof loadUserOrganisation;
  addAsMemberToOrganisationGroup: typeof addAsMemberToOrganisationGroup;
  removeAsMemberFromOrganisationGroup: typeof removeAsMemberFromOrganisationGroup;
  changeIsResponsibleStateOfMemberOnOrganisationGroup: typeof changeIsResponsibleStateOfMemberOnOrganisationGroup;
  selectedOrganisationGroup?: OrganisationGroup;
  selectedOrganisation?: Organisation;
  isOrganisationLoading: boolean;
}

interface OrganisationGroupEditMembersState {
  memberSearchNeedle: string;
  roleSearchNeedle: string;
  loadTriggered: boolean;
  organisationNotFoundError: boolean;
  organisationGroupNotFoundError: boolean;
}

class OrganisationGroupEditMembers extends Component<OrganisationGroupEditMembersProps, OrganisationGroupEditMembersState> {

  constructor(props: OrganisationGroupEditMembersProps) {
    super(props);
    this.state = {
      memberSearchNeedle: "",
      roleSearchNeedle: "",
      loadTriggered: false,
      organisationNotFoundError: false,
      organisationGroupNotFoundError: false,
    }
  }

  ionViewWillEnter() {
    const organisationId = this.props.match.params.organisationId;
    const organisationGroupId = this.props.match.params.organisationGroupId;
    if (this.state.loadTriggered) {
      logger.info("user organisation members already loaded", organisationId);
    } else {
      logger.info("load user organisation to edit group members", organisationId, organisationGroupId);
      this.props.loadUserOrganisation(organisationId, () => this.setState({...this.state, organisationNotFoundError: true}));
      this.props.loadUserOrganisationGroup(organisationId, organisationGroupId, () => this.setState({...this.state, organisationGroupNotFoundError: true}));
      this.setState({loadTriggered: true})
    }
  }

  ionViewWillLeave() {
    logger.info("reset user organisation load trigger");
    this.setState({loadTriggered: false})
  }

  render() {
    logger.debug("rendering organisation group edit", this.props.isOrganisationLoading, this.props.selectedOrganisation, this.props.selectedOrganisationGroup);
    const isLoading = this.props.isOrganisationLoading || this.props.selectedOrganisation === undefined || this.props.selectedOrganisationGroup === undefined
    const {selectedOrganisation, selectedOrganisationGroup} = this.props;
    const organisationGroupId = this.props.match.params.organisationGroupId;

    if (this.state.organisationNotFoundError) {
      return <ErrorMessage text={`Organisation with id ${this.props.match.params.organisationId} could not be found`}/>
    } else if (this.state.organisationGroupNotFoundError) {
      return <ErrorMessage
        text={`Organisation group with id ${this.props.match.params.organisationId}-${this.props.match.params.organisationGroupId} could not be found`}/>
    }

    let title;
    if (isLoading) {
      title = 'Edit members'
    } else {
      title = `Add or remove members for ${selectedOrganisation!.name} - ${selectedOrganisationGroup!.name}`
    }

    return (
      <IonPage id="organisation-group-edit-members-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton/>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.renderContent(isLoading, selectedOrganisation!!, selectedOrganisationGroup!!, organisationGroupId)}
        </IonContent>
      </IonPage>
    )
  }

  private renderContent(isLoading: boolean, organisation: Organisation, organisationGroup: OrganisationGroup, groupId: OrganisationGroupId) {
    return (
      <DataContainer
        isLoading={isLoading}
        contentRender={() => {
          return this.renderMembersContent(organisation, organisationGroup, groupId);
        }}
      />
    )
  }

  private renderMembersContent(organisation: Organisation, organisationGroup: OrganisationGroup, groupId: OrganisationGroupId) {
    const members = organisation.members;
    members.setNameFilter(this.state.memberSearchNeedle);
    return (
      <div className="content-container">
        <ListingByAvatarTitleAndSubtitle
          items={
            members.data.map((member, index) => {
              const belongsToGroup = member.belongsToGroup(groupId);
              let onRemove = undefined;
              let onAdd = undefined;

              // const changeResponsibleState = {
              //   render: (index: any) => {
              //     return (
              //       <IonItem key={index} className={"no-background no-border"}>
              //         <IonToggle
              //           checked={(organisationGroup.getMember(member.id) || {isResponsible: false}).isResponsible}
              //           onIonChange={(event: CustomEvent<ToggleChangeEventDetail>) => this.props.changeIsResponsibleStateOfMemberOnOrganisationGroup(
              //             {
              //               organisationId: organisation.id,
              //               organisationGroupId: groupId,
              //               organisationMemberId: member.id,
              //               isResponsible: event.detail.checked
              //             },
              //             () => logger.info(event.detail.checked ? "member became responsible" : "member is not responsible anymore"),
              //             (reason) => logger.error("could not change member responsible state", reason)
              //           )}
              //         />
              //         <IonLabel>Is responsible</IonLabel>
              //       </IonItem>
              //     )
              //   }
              // }

              if (belongsToGroup) {
                onRemove = () => {
                  logger.info(`Remove member from group ${groupId}`, member);
                  this.props.removeAsMemberFromOrganisationGroup(
                    {
                      organisationId: organisation.id,
                      organisationGroupId: groupId,
                      organisationMemberId: member.id
                    },
                    () => logger.info("member removed"),
                    (reason) => logger.error("could not remove member", reason)
                  )
                }
              } else {
                onAdd = () => {
                  logger.info(`Add member to group ${groupId}`, member);
                  this.props.addAsMemberToOrganisationGroup(
                    {
                      organisationId: organisation.id,
                      organisationGroupId: groupId,
                      organisationMemberId: member.id
                    },
                    () => logger.info("member added"),
                    (reason) => logger.error("could not add member", reason)
                  )
                }
              }

              return {
                title: member.name,
                avatar: member.avatar,
                subtitle: member.groups.map(g => organisation.groups.getById(g).name).join(','),
                onAdd: onAdd,
                onRemove: onRemove,
                // customActions: [changeResponsibleState]
              }
            })
          }
          onSearchNeedleChanged={newValue => this.setState({memberSearchNeedle: newValue})}
        />
      </div>
    )
  }
}

export default connect<OrganisationGroupEditMembersProps>({
  mapStateToProps: (state) => ({
    selectedOrganisation: state.organisationsState.selectedOrganisation,
    selectedOrganisationGroup: state.organisationsState.selectedOrganisationGroup,
    isOrganisationLoading: state.organisationsState.isOrganisationLoading,
  }),
  mapDispatchToProps: {
    loadUserOrganisation,
    loadUserOrganisationGroup,
    addAsMemberToOrganisationGroup,
    removeAsMemberFromOrganisationGroup,
    changeIsResponsibleStateOfMemberOnOrganisationGroup
  },
  component: withIonLifeCycle(OrganisationGroupEditMembers)
})
