import React, {Component} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';

import {RouteComponentProps} from "react-router";

import {connect} from "../../data/connect";
import Logger from "../../util/logger";
import logger from "../../util/logger";
import DataContainer from "../components/DataContainer";
import {loadUserOrganisations} from "../../data/organisations/organisations.actions";
import {loadCalendar, saveEvent, SaveEventRequest} from "../../data/calendar/calendar.actions";
import {Organisations} from "../../data/organisations/domain/Organisation";
import ErrorMessage from "../components/ErrorMessage";
import Reference from "../../data/domain/Reference";
import ViaeDateUtils from "../../util/ViaeDate.utils";
import TextInput from "../../components/inputs/TextInput";
import {FormValidator} from "../../util/FormValidator";
import ToggleInput from "../../components/inputs/ToggleInput";
import DateTimeInput from "../../components/inputs/DateTimeInput";
import NumberInput from "../../components/inputs/NumberInput";
import MultiSelectInput from "../../components/inputs/MultiSelectInput";
import SingleSelectInput from "../../components/inputs/SingleSelectInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import {CalendarData} from "../../data/calendar/domain/CalendarData";

interface SaveEventProps extends RouteComponentProps<{ eventId: string | undefined, action: "copy" | "edit" | undefined }, {}, { eventName: string | undefined }> {
  saveEvent: typeof saveEvent;
  organisations: Organisations;
  calendarData?: CalendarData;
  isOrganisationLoading: boolean;
  loadUserOrganisations: typeof loadUserOrganisations;
  loadCalendar: typeof loadCalendar;
}

interface SaveEventState {
  loadTriggered: boolean;
  isLoading: boolean;
  isExistingEventLoading: boolean;
  isCopyEvent: boolean;
  isEditEvent: boolean;
  title: string;
  form: {
    isAllDayEvent: boolean;
    isRecurrentEvent: boolean;
    date: string | undefined;
    name: string | undefined;
    recurringDayFrequency: number | undefined;
    recurCount: number | undefined;
    start: string | undefined;
    end: string | undefined;
    organisation: {
      reference: Reference | undefined,
      organisationGroups: Reference[] | undefined
    };
    location: string | undefined;
    comments: string | undefined
  },
  eventNotFoundError: boolean;
}

const INITIAL_STATE: SaveEventState = {
  loadTriggered: false,
  isLoading: false,
  isExistingEventLoading: false,
  isCopyEvent: false,
  isEditEvent: false,
  title: "",
  form: {
    isAllDayEvent: false,
    isRecurrentEvent: false,
    date: undefined,
    name: undefined,
    start: undefined,
    end: undefined,
    organisation: {
      reference: undefined,
      organisationGroups: undefined
    },
    location: undefined,
    comments: undefined,
    recurringDayFrequency: undefined,
    recurCount: undefined
  },
  eventNotFoundError: false
}

class SaveEvent extends Component<SaveEventProps, SaveEventState> {
  private validator = new FormValidator();

  constructor(props: SaveEventProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  ionViewWillEnter() {
    const eventId = this.props.match.params.eventId;
    if (this.state.loadTriggered) {
      Logger.info("save event already visible");
    } else {
      Logger.info("show save event", eventId);
      this.props.loadUserOrganisations();
      this.props.loadCalendar(() => logger.info("calendar got loaded"));
      const isEdit = this.props.match.params.eventId !== undefined;
      const action = this.props.match.params.action;
      let title;
      if (isEdit && action === "copy") { //Copy
        const eventName = this.props.location.state.eventName;
        if (!eventName) {
          console.error('eventName is not filled');
          this.setState({loadTriggered: false});
          this.props.history.push(`/calendar`);
        }
        title = `Copy event: ${eventName}`;
      }
      if (isEdit && action === "edit") { //Copy
        const eventName = this.props.location.state.eventName;
        if (!eventName) {
          console.error('eventName is not filled');
          this.setState({loadTriggered: false});
          this.props.history.push(`/calendar`);
        }
        title = `Edit event: ${eventName}`;
      } else if (isEdit) {
        title = `Event Detail`;
      } else { //create
        title = `Create new event`;
      }
      this.setState({
        loadTriggered: true,
        isLoading: false,
        isCopyEvent: isEdit && action === "copy",
        isEditEvent: isEdit && action === "edit",
        isExistingEventLoading: isEdit,
        title: title || ""
      })
    }
  }

  ionViewWillLeave() {
    this.validator.clearErrors();
    Logger.info("hide save event");
    this.setState(INITIAL_STATE);
  }

  shouldComponentUpdate(nextProps: Readonly<SaveEventProps>, nextState: Readonly<SaveEventState>, nextContext: any): boolean {
    return (nextState === undefined || nextState.loadTriggered) && (
      nextProps !== this.props || JSON.stringify(nextState) !== JSON.stringify(this.state)
    );
  }

  componentDidUpdate(prevProps: Readonly<SaveEventProps>, prevState: Readonly<SaveEventState>, snapshot?: any) {
    if (this.state.isCopyEvent || this.state.isEditEvent) {
      if (this.state.isExistingEventLoading && this.props.calendarData !== undefined) {
        //calendar got loaded
        const event = this.props.calendarData.getEventById(this.props.match.params.eventId!!);
        if (event) {
          this.setState({
            isExistingEventLoading: false,
            form: {
              ...this.state.form,
              name: event.name,
              start: `${event.date} ${event.timeStart}`,
              end: `${event.date} ${event.timeEnd}`,
              isAllDayEvent: event.isAllDayEvent,
              comments: event.comments,
              organisation: {
                reference: event.organisation,
                organisationGroups: event.groups
              }
            }
          });
        } else {
          //TODO redirect to not found
          logger.error("event not found for edit/copy", this.props.match.params.eventId)
          this.setState({loadTriggered: false});
          this.props.history.push(`/calendar`);
        }
      }
    }
  }

  render() {
    logger.debug("rendering save calendar event");
    if (this.state.eventNotFoundError) {
      return <ErrorMessage text={`event with id ${this.props.match.params.eventId} could not be found`}/>
    }
    const isLoading =
      this.state.loadTriggered
      && (this.props.isOrganisationLoading || this.props.organisations === undefined)
      && (!this.state.isCopyEvent || !this.state.isExistingEventLoading)
    logger.debug("is save event loading?", isLoading, this.props.isOrganisationLoading, this.state.isCopyEvent, this.state.isExistingEventLoading);
    return (
      <IonPage id="save-event-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton/>
            </IonButtons>
            <IonTitle>{this.state.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.renderContent(this.state.isLoading)}
        </IonContent>
      </IonPage>
    )
  }

  renderContent(isLoading: boolean) {
    const saveEvent = () => {
      if (this.validator.isFormValid()) {
        const eventId = this.props.match.params.eventId;
        const request: SaveEventRequest = {
          eventId: eventId,
          name: this.state.form.name!!,
          start: this.state.form.start!!,
          end: this.state.form.end,
          organisation: this.state.form.organisation.reference!!,
          organisationGroups: this.state.form.organisation.organisationGroups,
          location: this.state.form.location,
          comments: this.state.form.comments,
          isAllDayEvent: this.state.form.isAllDayEvent,
          isRecurrentEvent: this.state.form.isRecurrentEvent,
          recurCount: this.state.form.recurCount,
          recurringDayFrequency: this.state.form.recurringDayFrequency
        };
        this.props.saveEvent(request,
          () => {
            if (eventId) {
              logger.info("event updated", request);
            } else {
              logger.info("event created", request);
            }
            this.props.history.push(`/calendar`);
          },
          (reason: any) => {
            if (eventId) {
              logger.error("something went wrong while editing event", reason);
            } else {
              logger.error("something went wrong while creating event", reason);
            }
            //TODO error handling
          });
      }
    }
    const pickerFormat = this.state.form.isAllDayEvent ? "date" : "datetime";

    return (
      <DataContainer
        isLoading={isLoading}
        contentRender={() => {
          return (
            <div className="content-container">
              <IonList>
                <TextInput label="Event Name" required={true} value={this.state.form.name}
                           onChange={newValue => this.setState({form: {...this.state.form, name: newValue}})}
                           validator={this.validator}
                />
                <ToggleInput label="Is all day event?" required={false} value={this.state.form.isAllDayEvent}
                             onChange={newValue => this.setState({form: {...this.state.form, isAllDayEvent: newValue}})}
                             validator={this.validator}
                />
                <ToggleInput label="Is recurrent event?" required={false} value={this.state.form.isRecurrentEvent}
                             onChange={newValue => this.setState({form: {...this.state.form, isRecurrentEvent: newValue}})}
                             validator={this.validator}
                />
                <DateTimeInput label={this.state.form.isAllDayEvent ? 'Event date' : 'Event start'} required={true}
                               value={this.state.form.start}
                               onChange={newValue => this.setState({form: {...this.state.form, start: newValue}})}
                               validator={this.validator}
                               format={pickerFormat}
                               min={ViaeDateUtils.nowAsBackendFormattedString()}
                               max={ViaeDateUtils.toBackendFormattedString(ViaeDateUtils.addYearsToNowAsDate(2))}
                />
                <DateTimeInput label={'Event end'} required={!this.state.form.isAllDayEvent} value={this.state.form.end}
                               onChange={newValue => this.setState({form: {...this.state.form, end: newValue}})}
                               validator={this.validator}
                               format={pickerFormat}
                               min={this.state.form.start ? ViaeDateUtils.toBackendFormattedDateTimeString(new Date(this.state.form.start)) : undefined}
                               max={ViaeDateUtils.toBackendFormattedString(ViaeDateUtils.addYearsToNowAsDate(2))}
                               disabled={this.state.form.isAllDayEvent || !this.state.form.start}
                />
                <NumberInput label="Event is occurring every x days" required={this.state.form.isRecurrentEvent} value={this.state.form.recurringDayFrequency}
                             disabled={!this.state.form.isRecurrentEvent}
                             onChange={newValue => this.setState({form: {...this.state.form, recurringDayFrequency: newValue}})}
                             validator={this.validator}
                />
                <NumberInput label="Event will occur x times" required={this.state.form.isRecurrentEvent} value={this.state.form.recurCount}
                             disabled={!this.state.form.isRecurrentEvent}
                             onChange={newValue => this.setState({form: {...this.state.form, recurCount: newValue}})}
                             validator={this.validator}
                />
                <SingleSelectInput label="Organisation" header="Select an organisation" required={true}
                                   value={this.state.form.organisation.reference ? this.state.form.organisation.reference.ref : undefined}
                                   options={this.props.organisations.asReferences()}
                                   onChange={selectedRef => {
                                     const organisation = this.props.organisations.byId(selectedRef);
                                     if (organisation) {
                                       const groups = organisation.groups.asReferences()
                                       this.setState({
                                         ...this.state,
                                         form: {...this.state.form, organisation: {reference: organisation.asReference(), organisationGroups: groups}}
                                       })
                                     }
                                   }}
                                   validator={this.validator}
                />
                <MultiSelectInput label="Organisation groups" header="Select one or more organisation groups" required={false}
                                  value={this.state.form.organisation.organisationGroups ? this.state.form.organisation.organisationGroups.map(og => og.ref) : undefined}
                                  options={
                                    this.state.form.organisation.reference
                                      ? this.props.organisations.byId(this.state.form.organisation!!.reference!!.ref).groups.data
                                        .map((og, index) => new Reference(og.id, og.name))
                                      : []
                                  }
                                  disabled={!this.state.form.organisation.reference}
                                  onChange={selectedRefs => {
                                    if (this.state.form.organisation.reference) {
                                      const organisation = this.props.organisations.byId(this.state.form.organisation!!.reference!!.ref);
                                      const groups = organisation.groups.data.filter(g => selectedRefs.indexOf(g.id) !== -1).map(g => g.asReference());
                                      this.setState({
                                        ...this.state,
                                        form: {...this.state.form, organisation: {...this.state.form.organisation, organisationGroups: groups}}
                                      })
                                    }
                                  }}
                                  validator={this.validator}
                />
                <SingleSelectInput label="Organisation locations" header="Select an organisation location" required={false}
                                   value={this.state.form.location}
                                   options={
                                     this.state.form.organisation.reference
                                       ? this.props.organisations.byId(this.state.form.organisation!!.reference!!.ref).locations.data
                                         .map((loc, index) => new Reference(loc, loc))
                                       : []
                                   }
                                   disabled={!this.state.form.organisation.reference}
                                   onChange={selectedRef => this.setState({form: {...this.state.form, location: selectedRef}})}
                                   validator={this.validator}
                />
                <TextAreaInput label="Comments" required={false} value={this.state.form.comments}
                               onChange={newValue => this.setState({form: {...this.state.form, comments: newValue}})}
                               validator={this.validator}
                />
              </IonList>
              <IonRow>
                <IonCol>
                  <IonButton type="submit" expand="block" onClick={saveEvent}>{this.state.isEditEvent ? 'Save': 'Create'}</IonButton>
                </IonCol>
              </IonRow>
            </div>
          )
        }}
      />
    )
  }
}

export default connect<SaveEventProps>({
  mapStateToProps: (state) => ({
    isOrganisationLoading: state.organisationsState.isOrganisationLoading,
    organisations: state.organisationsState.organisations,
    calendarData: state.calendarState.calendar,
  }),
  mapDispatchToProps: {
    saveEvent: saveEvent,
    loadUserOrganisations,
    loadCalendar
  },
  component: withIonLifeCycle(SaveEvent)
})
