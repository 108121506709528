import {NotificationsState} from "./notifications.state";
import {NotificationsData} from "./staticdata/notificationdata";

export const getNotifications = async () => {
  const state: NotificationsState = {
    notifications: NotificationsData.DB,
    areNotificationsLoading: false,
  }
  return new Promise<NotificationsState>((resolve, reject) => {
    setTimeout(() => {
      resolve(state);
    }, 1000);
  });
}
export const markNewNotificationsRead = async () => {
  NotificationsData.DB.markNewNotificationsRead();
  const state: NotificationsState = {
    notifications: NotificationsData.DB,
    areNotificationsLoading: false,
  }
  return new Promise<NotificationsState>((resolve, reject) => {
    setTimeout(() => {
      resolve(state);
    }, 500);
  });
}
