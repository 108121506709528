import React, {Component} from 'react';
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton, IonFabList,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonList,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';

import {RouteComponentProps} from "react-router";
import {chatboxOutline, copyOutline, informationCircleOutline, listOutline, mapOutline, timeOutline, ellipsisVerticalOutline, pencilOutline} from "ionicons/icons";

import "./EventDetail.scss"
import {loadEvent} from "../../data/calendar/calendar.actions";
import {connect} from "../../data/connect";
import logger from "../../util/logger";
import DataContainer from "../components/DataContainer";
import TabBar from "../components/TabBar";
import {TEST_AVATAR} from "../../data/avatars";
import ErrorMessage from "../components/ErrorMessage";
import {CalendarEvent} from "../../data/calendar/domain/CalendarEvent";

interface EventDetailProps extends RouteComponentProps<{ eventId: string }> {
  loadEvent: typeof loadEvent;
  selectedEvent: CalendarEvent;
  isEventLoading: boolean;
}

interface EventDetailState {
  loadTriggered: boolean;
  activeView: 'data' | 'messages';
  eventNotFoundError: boolean;
}

class EventDetail extends Component<EventDetailProps, EventDetailState> {

  constructor(props: EventDetailProps) {
    super(props);
    this.state = {
      loadTriggered: false,
      activeView: 'data',
      eventNotFoundError: false,
    }
  }

  ionViewWillEnter() {
    const eventId = this.props.match.params.eventId;
    if (this.state.loadTriggered) {
      logger.info("event already loaded", eventId);
    } else {
      logger.info("load event", eventId);
      this.props.loadEvent(eventId, () => this.setState({...this.state, eventNotFoundError: true}));
      this.setState({
        loadTriggered: true,
        activeView: this.props.location.hash.substr(1).toLowerCase() === 'messages'? 'messages' : this.state.activeView
      })
    }
  }

  ionViewWillLeave() {
    logger.info("reset event load trigger");
    this.setState({loadTriggered: false})
  }

  render() {
    const isLoading = this.props.isEventLoading || this.props.selectedEvent === undefined
    const {selectedEvent} = this.props;

    if (this.state.eventNotFoundError) {
      return <ErrorMessage text={`event with id ${this.props.match.params.eventId} could not be found`}/>
    }

    return (
      <IonPage id="event-detail-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton/>
            </IonButtons>
            <IonTitle>{`Event Detail ${isLoading ? '' : `: ${selectedEvent!.name}`}`}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.renderContent(isLoading, selectedEvent)}
          {!isLoading &&
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton>
              <IonIcon icon={ellipsisVerticalOutline}
              />
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton>
                <IonIcon icon={copyOutline}
                         onClick={() => this.props.history.push({
                           pathname: `/calendar/events/${this.props.match.params.eventId}/copy`,
                           state: {eventName: selectedEvent.name}
                         })}
                />
              </IonFabButton>
              <IonFabButton>
                <IonIcon icon={pencilOutline}
                         onClick={() => this.props.history.push({
                           pathname: `/calendar/events/${this.props.match.params.eventId}/edit`,
                           state: {eventName: selectedEvent.name}
                         })}
                />
              </IonFabButton>
            </IonFabList>
          </IonFab>
          }
        </IonContent>
        <TabBar
          items={
            [
              {value: "data", label: "Data", icon: informationCircleOutline, onClick: () => this.setState({activeView: 'data'})},
              {value: "messages", label: "Messages", icon: chatboxOutline, onClick: () => this.setState({activeView: 'messages'})}
            ]
          }
          activeValue={this.state.activeView}
        />
      </IonPage>
    )
  }

  private renderContent(isLoading: boolean, event: CalendarEvent) {
    return (
      <DataContainer
        isLoading={isLoading}
        contentRender={() => {
          if (this.state.activeView === 'messages') {
            return this.renderMessagesContent();
          } else {
            return this.renderDataContent(event);
          }
        }}
      />
    )
  }

  private renderDataContent(event: CalendarEvent) {
    const endDate = event.date !== event.dateEnd ? `${event.dateEnd}: ` : '';
    return (
      <div className="content-container">
        <h1>{event.name}</h1>
        <p><IonIcon className={"viae-icon"} icon={timeOutline}
                    slot="start"/><i>{event.isAllDayEvent ? `${event.date}: all day` : `${event.date}: ${event.timeStart} - ${endDate}${event.timeEnd}`}</i></p>
        {event.location && <p><IonIcon className={"viae-icon"} icon={mapOutline} slot="start"/><i>{event.location}</i></p>}
        <p><IonIcon className={"viae-icon"} icon={listOutline} slot="start"/><i>{event.groups.map(g => g.name).join(", ")}</i></p>
        <hr/>
        <p>{event.comments}</p>
      </div>
    )
  }

  private renderMessagesContent() {
    return (
      <div className="content-container">
        <IonList>
          <IonItem>
            <IonAvatar slot="start">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                Net terug uit vakantie: splitsen we de standups of niet?.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                Dat zou ik ook wel willen weten.<br/>De laatste week was het alleszins wel het geval.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:44 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonCard slot="end">
              <IonCardContent>
                Onnozelaars, natuurlijk is het gesplitst.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
            <IonAvatar slot="end">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                ok, merci.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                thx.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:44 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonCard slot="end">
              <IonCardContent>
                Graag gedaan
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
            <IonAvatar slot="end">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
          </IonItem>
          <IonItemDivider/>
          <IonItem>
            {/*<IonTextarea rows={6} cols={20} placeholder="Enter any notes here..." value={text} onIonChange={e => setText(e.detail.value!)}></IonTextarea>*/}
            <IonTextarea rows={3} cols={20} placeholder="Enter response...">&nbsp;</IonTextarea>
            <IonRow>
              <IonCol>
                <IonButton type="submit" expand="block">Submit</IonButton>
              </IonCol>
            </IonRow>
          </IonItem>
        </IonList>
      </div>
    )
  }

}

export default connect<EventDetailProps>({
  mapStateToProps: (state) => ({
    selectedEvent: state.calendarState.selectedEvent,
    isEventLoading: state.calendarState.isEventLoading,
  }),
  mapDispatchToProps: {
    loadEvent,
  },
  component: withIonLifeCycle(EventDetail)
})
