export default class Reference {
  constructor(
    private _ref: string,
    private _name: string
  ) {
  }

  get ref(): string {
    return this._ref;
  }

  get name(): string {
    return this._name;
  }
}
