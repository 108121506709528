import React, {Component} from "react";
import {IonDatetime, IonItem, IonLabel} from "@ionic/react";
import {FormValidator, ValidatedElement} from "../../util/FormValidator";
import "./FormInput.scss";
import {DATE_FORMAT, DATE_TIME_FORMAT} from "../../Config";

interface Props {
  label: string;
  required: boolean;
  value: string | undefined
  onChange: (newValue: string | undefined) => void;
  validator: FormValidator;
  disabled?: boolean;
  format: "date" | "datetime";
  min: string | undefined;
  max: string | undefined;
}

interface State {
  inError: boolean;
  value: string | undefined;
}

export default class DateTimeInput extends Component<Props, State> implements ValidatedElement {

  constructor(props: Props) {
    super(props);
    this.props.validator.registerValidatedElement(this);
    this.state = {
      inError: false,
      value: props.value
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.state.inError && this.props.disabled) {
      this.clearErrors();
    }
  }

  render() {
    const {label, onChange, value, required, format, min, max} = this.props;
    const disabled = this.props.disabled || false;
    const {inError} = this.state;

    const pickerDisplayFormat = format === "date" ? DATE_FORMAT : DATE_TIME_FORMAT;
    const pickerFormat = format === "date" ? "DD MMM YYYY" : "DD MMM YYYY HH:mm";

    return (
      <div>
        <IonItem className={`input-item datetime-input-item ${inError ? 'in-error' : ''}`}>
          <IonLabel className={"label"} position="floating" color="primary">{label}{required ? '*' : ''}</IonLabel>
          <IonDatetime disabled={disabled} displayFormat={pickerDisplayFormat}
                       pickerFormat={pickerFormat}
                       value={value}
                       min={min}
                       max={max}
                       onIonChange={e => {
                         onChange(e.detail.value || undefined);
                         this.setState({value: e.detail.value || undefined});
                         this.clearErrors();
                       }}
          >&nbsp;</IonDatetime>
        </IonItem>
        {inError && <IonLabel position="fixed" className={"error-message"}>This field is required.</IonLabel>}
      </div>
    )
  }

  validate(): boolean {
    const inError = !this.props.disabled && this.props.required && (!this.state.value || this.state.value.length === 0);
    this.setState({inError: inError});
    return !inError;
  }

  clearErrors(): void {
    this.setState({inError: false});
  }
}
