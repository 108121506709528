import React, {Component} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, withIonLifeCycle} from '@ionic/react';

import {RouteComponentProps} from "react-router";

import "./OrganisationListing.scss"
import {loadUserOrganisations} from "../../data/organisations/organisations.actions";
import {connect} from "../../data/connect";
import DataContainer from "../components/DataContainer";
import logger from "../../util/logger";
import Logger from "../../util/logger";
import {Organisations} from "../../data/organisations/domain/Organisation";
import ListingByName from "../components/ListingByName";
import PageTitle from "../components/PageTitle";

interface OrganisationListingProps extends RouteComponentProps {
  loadUserOrganisations: typeof loadUserOrganisations;
  organisations: Organisations;
  areOrganisationsLoading: boolean;
}

interface OrganisationListingState {
  organisationSearchNeedle: string;
  loadTriggered: boolean;
}

class OrganisationListing extends Component<OrganisationListingProps, OrganisationListingState> {

  constructor(props: OrganisationListingProps) {
    super(props);
    this.state = {
      organisationSearchNeedle: "",
      loadTriggered: false
    }
  }

  ionViewWillEnter() {
    if (this.state.loadTriggered) {
      Logger.info("user organisations already loaded");
    } else {
      Logger.info("load users organisation");
      this.props.loadUserOrganisations();
      this.setState({loadTriggered: true})
    }
  }

  ionViewWillLeave() {
    Logger.info("reset user organisations load trigger");
    this.setState({loadTriggered: false})
  }

  render() {
    return (
      <IonPage id="organisation-listing-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton/>
            </IonButtons>
            <IonTitle>{`Organisations overview`}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="content-container">
            <DataContainer
              isLoading={this.props.areOrganisationsLoading}
              contentRender={() => {
                return (
                  <ListingByName
                    items={
                      this.props.organisations.data.map((organisation, index) => {
                        return {
                          name: organisation.name,
                          onView: () => {
                            logger.info("navigate to organisation detail", organisation.id)
                            this.props.history.push(`/organisations/${organisation.id}`)
                          }
                        }
                      })
                    }
                    onSearchNeedleChanged={this.organisationSearchNeedleChanged}
                  />
                )
              }}
            />
          </div>
        </IonContent>

      </IonPage>
    );
  }

  private organisationSearchNeedleChanged(newValue: string) {
    this.setState({organisationSearchNeedle: newValue});
  }

}

export default connect<OrganisationListingProps>({
  mapStateToProps: (state) => ({
    organisations: state.organisationsState.organisations,
    areOrganisationsLoading: state.organisationsState.areOrganisationsLoading,
  }),
  mapDispatchToProps: {
    loadUserOrganisations
  },
  component: withIonLifeCycle(OrganisationListing)
})
