import Reference from "../../domain/Reference";
import {OrganisationId} from "../../organisations/domain/Organisation";

export type CalendarEventId = string

interface CalendarEventFilter {
  selectedOrganisations: OrganisationId[] | undefined;
}

const DEFAULT_FILTER = {selectedOrganisations: undefined};

export class CalendarEvents {
  constructor(private _events: CalendarEvent[] = [], private filter: CalendarEventFilter = DEFAULT_FILTER) {
  }

  clearFilter() {
    this.filter = DEFAULT_FILTER;
    return this;
  }

  get data(): CalendarEvent[] {
    return this._events.filter((ce: CalendarEvent) => {
      const organisationMatches = this.filter.selectedOrganisations === undefined || this.filter.selectedOrganisations.indexOf(ce.organisation.ref) !== -1
      return organisationMatches
    }).sort((a, b) => a.name.localeCompare(b.name));
  }

  get single(): CalendarEvent {
    if (this.data.length === 1) {
      return this.data[0];
    } else {
      throw Error("Not one single result for calendar event list: " + this.data.map(g => g.name).join(','))
    }
  }

  get length(): number {
    return this.data.length;
  }

  getById(id: CalendarEventId): CalendarEvent {
    return this._events.filter(e => e.id === id)[0]
  }

  setOrganisationFilter(organisationIds: OrganisationId[] | undefined) {
    this.filter = {...this.filter, selectedOrganisations: organisationIds};
  }

  addEvent(calendarEvent: CalendarEvent) {
    this._events.push(calendarEvent);
  }

  deleteEvent(eventId: string) {
    this._events = this._events.filter(e => e.id !== eventId);
  }
}

export class CalendarEvent {
  constructor(
    private _id: CalendarEventId,
    private _date: string,
    private _dateEnd: string,
    private _timeStart: string,
    private _timeEnd: string | undefined,
    private _name: string,
    private _location: string | undefined,
    private _organisation: Reference,
    private _groups?: Reference[],
    private _comments?: string,
    private _isAllDayEvent: boolean = false
  ) {
  }

  get id(): CalendarEventId {
    return this._id;
  }

  get date(): string {
    return this._date;
  }

  get dateEnd(): string {
    return this._dateEnd;
  }

  get timeStart(): string {
    return this._timeStart;
  }

  get timeEnd(): string {
    return this._timeEnd || "";
  }

  get name(): string {
    return this._name;
  }

  get location(): string {
    return this._location || "";
  }

  get organisation(): Reference {
    return this._organisation;
  }

  get comments(): string {
    return this._comments || '';
  }

  get groups(): Reference[] {
    return this._groups || [];
  }

  get isAllDayEvent(): boolean {
    return this._isAllDayEvent;
  }
}
