import {OrganisationGroupMember, OrganisationGroupMembers} from "./OrganisationGroupMember";
import {OrganisationMemberId} from "./OrganisationMember";
import {OrganisationGroupMemberRole, OrganisationGroupMemberRoles} from "./OrganisationGroupMemberRole";
import Reference from "../../domain/Reference";

export type OrganisationGroupId = string

interface OrganisationGroupFilter {
  name: string;
  id: string;
}

const DEFAULT_FILTER = {name: "", id: ""};

export class OrganisationGroups {
  constructor(private _groups: OrganisationGroup[] = [], private filter: OrganisationGroupFilter = DEFAULT_FILTER) {
  }

  setNameFilter(filter: string): OrganisationGroups {
    this.filter = {...this.filter, name: filter};
    return this;
  }

  setIdFilter(id: string): OrganisationGroups {
    this.filter = {...this.filter, id: id};
    return this;
  }

  clearFilter() {
    this.filter = DEFAULT_FILTER;
    return this;
  }

  get data(): OrganisationGroup[] {
    return this._groups.filter((og: OrganisationGroup) => {
      const nameMatch = og.name.toLowerCase().indexOf(this.filter.name) !== -1;
      const idMatch = og.id.toLowerCase().indexOf(this.filter.id) !== -1;
      return nameMatch && idMatch;
    });
  }

  get single(): OrganisationGroup {
    if (this.data.length === 1) {
      return this.data[0];
    } else {
      throw Error("Not one single result for group list: " + this.data.map(g => g.name).join(','))
    }
  }

  add(group: OrganisationGroup) { //TODO remove this method when BE gets created
    this._groups.push(group);
  }

  getById(id: string): OrganisationGroup {
    return this._groups.filter(r => r.id === id)[0]
  }

  asReferences(): Reference[] {
    return this.data.map(og => new Reference(og.id, og.name));
  }
}

export class OrganisationGroup {
  private _members: OrganisationGroupMembers;
  private _availableMemberRoles: OrganisationGroupMemberRoles;

  constructor(
    private _id: OrganisationGroupId,
    private _name: string,
    _members: OrganisationGroupMember[] = [],
    _availableMemberRoles: OrganisationGroupMemberRole[] = []
  ) {
    this._members = new OrganisationGroupMembers(_members);
    this._availableMemberRoles = new OrganisationGroupMemberRoles(_availableMemberRoles);
  }

  get id(): OrganisationGroupId {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get members(): OrganisationGroupMembers {
    return this._members;
  }

  get responsibles(): OrganisationGroupMembers {
    return this._members.setIsResponsibleFilter(true)
  }

  getMember(id: OrganisationMemberId): OrganisationGroupMember | undefined {
    const members = this.members.data.filter(m => m.id === id);
    if (!members || members.length === 0) {
      return undefined;
    } else {
      return members[0];
    }
  }

  get availableMemberRoles(): OrganisationGroupMemberRoles {
    return this._availableMemberRoles;
  }

  asReference(): Reference {
    return new Reference(this.id, this.name);
  }
}
