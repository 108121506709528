import React from 'react';
import {RouteComponentProps, useLocation, withRouter} from 'react-router';

import {IonBadge, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle} from '@ionic/react';
import {calendarOutline, help, homeOutline, logOut, notificationsOutline, person} from 'ionicons/icons';

import {connect} from '../data/connect';
import {setDarkMode} from '../data/user/user.actions';

import './Menu.css'
import {Notifications} from "../data/notifications/domain/Notification";

const routes = {
  appPages: [
    {title: 'Calendar', path: '/calendar', icon: calendarOutline},
    {title: 'Organisations', path: '/organisations', icon: homeOutline},
    {title: 'Notifications', path: '/notifications', icon: notificationsOutline, withNotificationCount: true},
    // { title: 'Schedule', path: '/tabs/schedule', icon: calendarOutline },
    // { title: 'Speakers', path: '/tabs/speakers', icon: peopleOutline },
    // { title: 'Map', path: '/tabs/map', icon: mapOutline },
    // { title: 'About', path: '/tabs/about', icon: informationCircleOutline }
  ],
  loggedInPages: [
    {title: 'Account', path: '/account', icon: person},
    {title: 'Support', path: '/support', icon: help},
    {title: 'Logout', path: '/logout', icon: logOut}
  ],
  loggedOutPages: [
    // { title: 'Login', path: '/login', icon: logIn },
    {title: 'Support', path: '/support', icon: help},
    // { title: 'Signup', path: '/signup', icon: personAdd }
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  withNotificationCount?: boolean,
  routerDirection?: string
}

interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
  notifications: Notifications;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps {
}

const Menu: React.FC<MenuProps> = ({darkMode, history, isAuthenticated, setDarkMode, menuEnabled, notifications}) => {
  const location = useLocation();

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={`${location.pathname.startsWith(p.path) ? 'selected' : undefined}`}>
            {p.withNotificationCount && notifications.unReadNotificationCount > 0 && <IonBadge slot="start">{notifications.unReadNotificationCount}</IonBadge>}
            <IonIcon slot="start" icon={p.icon}/>
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" disabled={!menuEnabled} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>Doofr</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
        {/*<IonList lines="none">*/}
        {/*  <IonListHeader>Account</IonListHeader>*/}
        {/*  {isAuthenticated ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}*/}
        {/*<IonItem>*/}
        {/*  <IonIcon slot="start" icon={moonOutline}></IonIcon>*/}
        {/*  <IonLabel>Dark Mode</IonLabel>*/}
        {/*<IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />*/}
        {/*</IonItem>*/}
        {/*</IonList>*/}
        {/*<IonList lines="none">*/}
        {/*  <IonListHeader>Tutorial</IonListHeader>*/}
        {/*  <IonItem button onClick={() => {*/}
        {/*    history.push('/tutorial');*/}
        {/*  }}>*/}
        {/*    <IonIcon slot="start" icon={hammer} />*/}
        {/*    Show Tutorial*/}
        {/*  </IonItem>*/}
        {/*</IonList>*/}
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.data.menuEnabled,
    notifications: state.notificationsState.notifications
  }),
  mapDispatchToProps: ({
    setDarkMode
  }),
  component: withRouter(Menu)
})
