import React, {Component} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';

import {RouteComponentProps} from "react-router";

import {connect} from "../../data/connect";
import Logger from "../../util/logger";
import logger from "../../util/logger";
import DataContainer from "../components/DataContainer";
import {createOrganisationGroup, CreateOrganisationGroupRequest, loadUserOrganisation} from "../../data/organisations/organisations.actions";
import {Organisation} from "../../data/organisations/domain/Organisation";
import ErrorMessage from "../components/ErrorMessage";
import {OrganisationMemberId} from "../../data/organisations/domain/OrganisationMember";
import ListingByAvatarTitleAndSubtitle from "../components/ListingByAvatarTitleAndSubtitle";

interface SaveOrganisationGroupProps extends RouteComponentProps<{ organisationId: string, organisationGroupId?: string }, {}, { organisationName?: string }> {
  createOrganisationGroup: typeof createOrganisationGroup;
  loadUserOrganisation: typeof loadUserOrganisation;
  organisation?: Organisation;
  isOrganisationLoading: boolean;
}

interface SaveOrganisationGroupState {
  loadTriggered: boolean;
  isLoading: boolean;
  title: string;
  memberSearchNeedle: string;
  organisationName?: string;
  form: {
    groupName?: string,
    responsibles: OrganisationMemberId[]
  },
  organisationNotFoundError: boolean;
}

const INITIAL_STATE: SaveOrganisationGroupState = {
  loadTriggered: false,
  isLoading: false,
  title: "",
  memberSearchNeedle: "",
  organisationName: undefined,
  form: {
    responsibles: []
  },
  organisationNotFoundError: false
}

class SaveOrganisationGroup extends Component<SaveOrganisationGroupProps, SaveOrganisationGroupState> {

  constructor(props: SaveOrganisationGroupProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  ionViewWillEnter() {
    const organisationId = this.props.match.params.organisationId;
    if (this.state.loadTriggered) {
      Logger.info("save organisation already visible");
    } else {
      Logger.info("show save organisation", organisationId);
      this.props.loadUserOrganisation(organisationId, () => this.setState({...this.state, organisationNotFoundError: true}));
      const isEdit = this.props.match.params.organisationGroupId !== undefined;
      let title, organisationName;
      if (isEdit) {
        title = `Organisation Detail`;
      } else { //create
        const organisationName = this.props.location.state.organisationName;
        if (!organisationName) {
          console.error('organisationName is not filled');
          this.setState({loadTriggered: false});
          this.props.history.push(`/organisations/${organisationId}`);
        } else {
          title = `Create new organisation group for organisation ${organisationName}`;
        }
      }
      this.setState({
        loadTriggered: true,
        isLoading: false,
        title: title || "",
        organisationName: organisationName
      })
    }
  }

  ionViewWillLeave() {
    Logger.info("hide save organisation");
    this.setState(INITIAL_STATE);
  }

  render() {
    if (this.state.organisationNotFoundError) {
      return <ErrorMessage text={`Organisation with id ${this.props.match.params.organisationId} could not be found`}/>
    }
    const isLoading = this.props.isOrganisationLoading || this.props.organisation === undefined
    logger.debug("is save organisation group loading?", isLoading, this.props.isOrganisationLoading, this.props.organisation);
    return (
      <IonPage id="save-organisation-group-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton/>
            </IonButtons>
            <IonTitle>{this.state.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.renderContent(this.state.isLoading)}
        </IonContent>
      </IonPage>
    )
  }

  renderContent(isLoading: boolean) {
    const createGroup = () => {
      const request: CreateOrganisationGroupRequest = {
        groupName: this.state.form.groupName || "", //TODO validation
        organisationId: this.props.match.params.organisationId,
        responsibles: this.state.form.responsibles
      };
      this.props.createOrganisationGroup(request,
        () => {
          logger.info("organisation created", request);
          this.props.history.push(`/organisations/${request.organisationId}`);
        },
        (reason: any) => {
          logger.error("something went wrong while creating group", reason);
          //TODO error handling
        });
    }
    return (
      <DataContainer
        isLoading={isLoading}
        contentRender={() => {
          return (
            <div className="content-container">
              <IonList>
                <IonItem>
                  <IonLabel position="floating" color="primary">Group name</IonLabel>
                  <IonInput value={this.state.form.groupName}
                            onIonChange={e => this.setState({form: {...this.state.form, groupName: e.detail.value || undefined}})}>&nbsp;</IonInput>
                </IonItem>
              </IonList>
              {this.props.organisation && this.renderMembersContent(this.props.organisation!!)}
              <IonRow>
                <IonCol>
                  <IonButton type="submit" expand="block" onClick={createGroup}>Create</IonButton>
                </IonCol>
              </IonRow>
            </div>
          )
        }}
      />
    )
  }

  private renderMembersContent(organisation: Organisation) {
    const members = organisation.members;
    members.setNameFilter(this.state.memberSearchNeedle);
    return (
      <div className="">
        <IonListHeader>Add responsibles for group</IonListHeader>
        <ListingByAvatarTitleAndSubtitle
          items={
            members.data.map((member, index) => {
              const belongsToGroup = this.state.form.responsibles.indexOf(member.id) !== -1;
              let onRemove = undefined;
              let onAdd = undefined;

              if (belongsToGroup) {
                onRemove = () => {
                  logger.info(`Remove member from group`, member);
                  this.setState({form: {...this.state.form, responsibles: this.state.form.responsibles.filter(r => r !== member.id)}})
                }
              } else {
                onAdd = () => {
                  logger.info(`Add member to group`, member);
                  this.setState({form: {...this.state.form, responsibles: [...this.state.form.responsibles, member.id]}})
                }
              }

              return {
                title: member.name,
                avatar: member.avatar,
                subtitle: "",
                onAdd: onAdd,
                onRemove: onRemove
              }
            })
          }
          onSearchNeedleChanged={newValue => this.setState({memberSearchNeedle: newValue})}
        />
      </div>
    )
  }

}

export default connect<SaveOrganisationGroupProps>({
  mapStateToProps: (state) => ({
    isOrganisationLoading: state.organisationsState.isOrganisationLoading,
    organisation: state.organisationsState.selectedOrganisation
  }),
  mapDispatchToProps: {
    createOrganisationGroup,
    loadUserOrganisation
  },
  component: withIonLifeCycle(SaveOrganisationGroup)
})
