import React, {Component} from "react";
import "./ErrorMessage.scss"

interface ErrorMessageProps {
  text: any;
}

export default class ErrorMessage extends Component<ErrorMessageProps> {

  render() {
    return (
      <p className={"error-message"}>
        {this.props.text}
      </p>
    )
  }
}
