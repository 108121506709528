import {
  AlertButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, {Component} from 'react';
import {getMode} from "@ionic/core";
import {OrganisationId} from "../../../data/organisations/domain/Organisation";
import Reference from "../../../data/domain/Reference";

interface CalendarItemListFilterProps {
  onDismissModal: () => void;
  onCalendarItemOrganisationFilterChanged: (organisations?: OrganisationId[]) => void;
  availableOrganisations: Reference[];
  selectedOrganisations: OrganisationId[];
}

interface CalendarItemListFilterState {
  showAlert: boolean;
  alertHeader: string;
  alertButtons: (AlertButton | string)[];
}

class CalendarItemListFilter extends Component<CalendarItemListFilterProps, CalendarItemListFilterState> {
  constructor(props: CalendarItemListFilterProps) {
    super(props);
    this.state = {
      showAlert: false,
      alertHeader: '',
      alertButtons: []
    }
  }

  render() {
    const ios = getMode() === 'ios';
    const {onDismissModal} = this.props;

    const handleDeselectAll = () => {
      this.props.onCalendarItemOrganisationFilterChanged(undefined);
    }
    const handleSelectAll = () => {
      this.props.onCalendarItemOrganisationFilterChanged(this.props.availableOrganisations.map(r => r.ref));
    }
    const toggleTrackFilter = (organisationReference: Reference) => {
      const index = this.props.selectedOrganisations.indexOf(organisationReference.ref);
      if (index === -1) {
        this.props.onCalendarItemOrganisationFilterChanged([...this.props.selectedOrganisations, organisationReference.ref])
      } else {
        this.props.onCalendarItemOrganisationFilterChanged(this.props.selectedOrganisations.filter(o => o !== organisationReference.ref))
      }
    }

    return (
      <>
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonButtons slot="start">
              {ios &&
              <IonButton onClick={onDismissModal}>Cancel</IonButton>
              }
              {!ios &&
              <IonButton onClick={handleDeselectAll}>Reset</IonButton>
              }
            </IonButtons>

            <IonTitle>
              <small>Filter calendar events</small>
            </IonTitle>

            <IonButtons slot="end">
              <IonButton onClick={onDismissModal} strong>Done</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList lines={ios ? 'inset' : 'full'}>
            <IonListHeader>Organisations</IonListHeader>

            {this.props.availableOrganisations.map((organisationReference, index) => (
              <IonItem key={organisationReference.ref}>
                <IonLabel>{organisationReference.name}</IonLabel>
                <IonCheckbox
                  onClick={() => toggleTrackFilter(organisationReference)}
                  checked={this.props.selectedOrganisations.indexOf(organisationReference.ref) !== -1}
                  color="primary"
                  value={organisationReference.ref}
                >&nbsp;</IonCheckbox>
              </IonItem>
            ))}
          </IonList>
        </IonContent>

        {ios &&
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={handleDeselectAll}>Deselect All</IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSelectAll}>Select All</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
        }
      </>
    );
  }
}

export default CalendarItemListFilter;
