import {OrganisationsState} from "./organisations.state";
import {OrganisationGroup, OrganisationGroupId} from "./domain/OrganisationGroup";
import {Organisation, OrganisationId, Organisations} from "./domain/Organisation";
import {OrganisationMemberId} from "./domain/OrganisationMember";
import {
  AddAsMemberToOrganisationGroupRequest,
  ChangeIsResponsibleStateOfMemberOnOrganisationGroupRequest,
  CreateOrganisationBoardRoleRequest,
  CreateOrganisationGroupRequest,
  CreateOrganisationGroupRoleRequest, CreateOrganisationLocationRequest,
  EditOrganisationGroupMemberRequest,
  EditOrganisationMemberRequest,
  RemoveAsMemberFromOrganisationGroup
} from "./organisations.actions";
import {BASF, Harmonie, Toneel} from "./staticdata/organisationdata";
import {OrganisationMemberRole} from "./domain/OrganisationMemberRole";
import {organisationGroupDatabase} from "./staticdata/organisationgroupdata";
import {OrganisationGroupMember} from "./domain/OrganisationGroupMember";
import {OrganisationGroupMemberRole} from "./domain/OrganisationGroupMemberRole";

export const getOrganisations = async () => {
  const state: OrganisationsState = {
    organisations: new Organisations([
      new Organisation("1", "De harmonie", []),
      new Organisation("2", "Korfbal", []),
      BASF.ORGANISATION,
      new Organisation("4", "Axians", []),
      Toneel.ORGANISATION,
      Harmonie.ORGANISATION,
    ]),
    selectedOrganisation: undefined,
    selectedOrganisationGroup: undefined,
    selectedOrganisationGroupMember: undefined,
    selectedOrganisationMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }
  return new Promise<OrganisationsState>((resolve, reject) => {
    setTimeout(() => {
      resolve(state);
    }, 1000);
  });
}

export const getOrganisation = async (organisationId: OrganisationId, onFound: (state: OrganisationsState) => void, onNotFound: () => void) => {
  const organisation = determineOrganisationState(organisationId);
  if (organisation !== undefined) {
    setTimeout(() => {
      onFound(organisation!!);
    }, 1000);
  } else {
    onNotFound()
  }
}

export const getOrganisationGroup = async (organisationId: OrganisationId, organisationGroupId: OrganisationGroupId, onFound: (state: OrganisationsState) => void, onNotFound: () => void) => { //TODO not found
  let organisationGroup = determineOrganisationGroup(organisationId, organisationGroupId);
  const organisation = determineOrganisationState(organisationId);

  const state: OrganisationsState = {
    organisations: new Organisations([]),
    selectedOrganisation: organisation!!.selectedOrganisation,
    selectedOrganisationGroup: organisationGroup,
    selectedOrganisationMember: undefined,
    selectedOrganisationGroupMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }
  if (organisationGroup !== undefined) {
    setTimeout(() => {
      onFound(state);
    }, 1000);
  } else {
    onNotFound()
  }
}

export const mockCreateOrganisationGroup = async (request: CreateOrganisationGroupRequest) => {
  const organisation = determineOrganisationState(request.organisationId)!!;
  const groups = organisation.selectedOrganisation!!.groups!!;

  const responsibles = request.responsibles
    .map(r => organisation.selectedOrganisation!!.members.data.filter(m => m.id === r)[0])
    .map(r => new OrganisationGroupMember(r.id, r.name, true, [], r.avatar));

  const group = new OrganisationGroup((groups.data.length + 1).toString(), request.groupName, responsibles);
  groups.add(group);

  const organisationGroups = organisationGroupDatabase.get(request.organisationId);
  if (organisationGroups) {
    organisationGroups.push(group);
  } else {
    throw Error("group adding not supported for organisation " + request.organisationId);
  }
}

export const mockCreateOrganisationBoardRole = async (request: CreateOrganisationBoardRoleRequest) => {
  const organisation = determineOrganisationState(request.organisationId)!!;
  const roles = organisation.selectedOrganisation!!.availableMemberRoles!!;
  roles.add(new OrganisationMemberRole((roles.data.length + 1).toString(), request.boardRoleName));
}

export const mockCreateOrganisationLocation = async (request: CreateOrganisationLocationRequest) => {
  const organisation = determineOrganisationState(request.organisationId)!!;
  const locations = organisation.selectedOrganisation!!.locations!!;
  locations.add(request.locationName);
}

export const mockCreateOrganisationGroupRole = async (request: CreateOrganisationGroupRoleRequest) => {
  const organisation = determineOrganisationState(request.organisationId)!!;
  const organisationGroup = determineOrganisationGroup(request.organisationId, request.organisationGroupId)!!;
  const roles = organisationGroup.availableMemberRoles;
  roles.add(new OrganisationGroupMemberRole((roles.data.length + 1).toString(), request.groupRoleName));
}

export const mockEditOrganisationMember = async (request: EditOrganisationMemberRequest) => {
  const organisation = determineOrganisationState(request.organisationId)!!;
  const member = organisation.selectedOrganisation!!.members.getById(request.organisationMemberId);
  member.edit(request.isBoardMember, request.roles);
}

export const mockEditOrganisationGroupMember = async (request: EditOrganisationGroupMemberRequest) => {
  const organisation = determineOrganisationState(request.organisationId)!!;
  const organisationGroup = determineOrganisationGroup(request.organisationId, request.organisationGroupId)!!;
  const member = organisationGroup.members.getById(request.organisationGroupMemberId);
  member.edit(request.isResponsible, request.roles);
}

export const mockAddOrganisationGroupMemberToGroup = async (request: AddAsMemberToOrganisationGroupRequest) => {
  let organisationGroup = determineOrganisationGroup(request.organisationId, request.organisationGroupId)!!;
  const organisation = determineOrganisationState(request.organisationId)!!.selectedOrganisation!!;

  const organisationMember = organisation.members.data.filter(m => m.id === request.organisationMemberId)[0];
  organisationMember.addGroup(request.organisationGroupId);

  organisationGroup.members.add(
    new OrganisationGroupMember(
      organisationMember.id,
      organisationMember.name,
      false,
      [],
      organisationMember.avatar
    )
  );

  const state: OrganisationsState = {
    organisations: new Organisations([]),
    selectedOrganisation: organisation,
    selectedOrganisationGroup: organisationGroup,
    selectedOrganisationGroupMember: undefined,
    selectedOrganisationMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }

  return new Promise<OrganisationsState>((resolve, reject) => {
    setTimeout(() => {
      resolve(state);
    }, 50);
  });
}

export const mockRemoveOrganisationGroupMemberFromGroup = async (request: RemoveAsMemberFromOrganisationGroup) => {
  let organisationGroup = determineOrganisationGroup(request.organisationId, request.organisationGroupId)!!;
  organisationGroup.members.remove(request.organisationMemberId);

  const organisation = determineOrganisationState(request.organisationId)!!.selectedOrganisation!!;
  const organisationMember = organisation.members.data.filter(m => m.id === request.organisationMemberId)[0];
  organisationMember.removeGroup(request.organisationGroupId);

  const state: OrganisationsState = {
    organisations: new Organisations([]),
    selectedOrganisation: organisation,
    selectedOrganisationGroup: organisationGroup,
    selectedOrganisationMember: undefined,
    selectedOrganisationGroupMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }

  return new Promise<OrganisationsState>((resolve, reject) => {
    setTimeout(() => {
      resolve(state);
    }, 50);
  });
}

export const mockChangeIsResponsibleStateOfMemberOnOrganisationGroup = async (request: ChangeIsResponsibleStateOfMemberOnOrganisationGroupRequest) => {
  let organisationGroup = determineOrganisationGroup(request.organisationId, request.organisationGroupId)!!;
  const organisation = determineOrganisationState(request.organisationId)!!.selectedOrganisation!!;

  const groupMember = organisationGroup.getMember(request.organisationMemberId);
  if (groupMember) {
    groupMember.setResponsible(request.isResponsible);
  } else {
    const organisationMember = organisation.members.data.filter(m => m.id === request.organisationMemberId)[0];
    organisationMember.addGroup(request.organisationGroupId)
    organisationGroup.members.add(
      new OrganisationGroupMember(
        organisationMember.id,
        organisationMember.name,
        request.isResponsible,
        [],
        organisationMember.avatar
      )
    );
  }


  const state: OrganisationsState = {
    organisations: new Organisations([]),
    selectedOrganisation: organisation,
    selectedOrganisationGroup: organisationGroup,
    selectedOrganisationMember: undefined,
    selectedOrganisationGroupMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }

  return new Promise<OrganisationsState>((resolve, reject) => {
    setTimeout(() => {
      resolve(state);
    }, 50);
  });
}

export const getOrganisationMember = async (organisationId: OrganisationId, organisationMemberId: OrganisationMemberId) => { //TODO not found
  const organisationState = determineOrganisationState(organisationId)!!;

  const state: OrganisationsState = {
    organisations: new Organisations([]),
    selectedOrganisation: organisationState.selectedOrganisation,
    selectedOrganisationGroup: undefined,
    selectedOrganisationMember: organisationState.selectedOrganisation!!.members!!.getById(organisationMemberId),
    selectedOrganisationGroupMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }
  return new Promise<OrganisationsState>((resolve, reject) => {
    setTimeout(() => {
      resolve(state);
    }, 1000);
  });
}

export const getOrganisationGroupMember = async (organisationId: OrganisationId, organisationGroupId: OrganisationGroupId, organisationMemberId: OrganisationMemberId) => { //TODO not found
  const organisationState = determineOrganisationState(organisationId)!!;
  const organisationGroup = determineOrganisationGroup(organisationId, organisationGroupId)!!;

  const state: OrganisationsState = {
    organisations: new Organisations([]),
    selectedOrganisation: organisationState.selectedOrganisation,
    selectedOrganisationGroup: organisationGroup,
    selectedOrganisationMember: organisationState.selectedOrganisation!!.members!!.getById(organisationMemberId),
    selectedOrganisationGroupMember: organisationGroup.members.getById(organisationMemberId),
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }
  return new Promise<OrganisationsState>((resolve, reject) => {
    setTimeout(() => {
      resolve(state);
    }, 1000);
  });
}

const determineOrganisationState = (organisationId: OrganisationId) => {
  let organisation: OrganisationsState | undefined;
  if (organisationId.toString() === "3") {
    organisation = BASF.ORGANISATION_DETAIL_STATE;
  } else if (organisationId.toString() === "5") {
    organisation = Toneel.ORGANISATION_DETAIL_STATE;
  } else if (organisationId.toString() === "6") {
    organisation = Harmonie.ORGANISATION_DETAIL_STATE;
  } else {
    organisation = undefined;
  }
  return organisation;
}

function determineOrganisationGroup(organisationId: string, organisationGroupId: string) {
  let organisationGroup = undefined;
  const organisationGroupDatabaseElement = organisationGroupDatabase.get(organisationId);
  if (organisationGroupDatabaseElement !== undefined) {
    const group = organisationGroupDatabaseElement.filter(g => g.id === organisationGroupId)
    if (group && group.length > 0) {
      organisationGroup = group[0];
    }
  }
  return organisationGroup;
}

