import {NotificationsState} from "./notifications.state";
import {NotificationsActions} from "./notifications.actions";

export const notificationsReducer = (state: NotificationsState, action: NotificationsActions): NotificationsState => {
  switch (action.type) {
    case 'set-notifications-loading': {
      return {...state, areNotificationsLoading: action.isLoading};
    }
    case 'set-notifications-data': {
      return {...state, ...action.data};
    }
  }
}
