import {Organisation, Organisations} from "../domain/Organisation";
import {OrganisationGroup} from "../domain/OrganisationGroup";
import {OrganisationMember} from "../domain/OrganisationMember";
import {TEST_AVATAR} from "../../avatars";
import {OrganisationMemberRole} from "../domain/OrganisationMemberRole";
import {OrganisationsState} from "../organisations.state";

export class BASF {
  static ORGANISATION = new Organisation(
    "3",
    "BASF",
    [
      new OrganisationGroup("1", "Phoenix"),
      new OrganisationGroup("2", "BDAM"),
      new OrganisationGroup("3", "TOEC")
    ],
    [
      new OrganisationMember("1", "Maarten Vandeperre", false, TEST_AVATAR, [new OrganisationMemberRole("1", "developer")]),
      new OrganisationMember("2", "Dominic Maes", false, TEST_AVATAR, []),
      new OrganisationMember("3", "Sebastiaan Van Holm", false, TEST_AVATAR, []),
      new OrganisationMember("4", "Thomas Roelens", false, TEST_AVATAR, []),
      new OrganisationMember("5", "Sabine Vuylsteke", true, TEST_AVATAR, []),
      new OrganisationMember("6", "Peter De Clerq", true, TEST_AVATAR, [new OrganisationMemberRole("4", "platform architect")]),
      new OrganisationMember("7", "Filip Nollet", true, TEST_AVATAR, []),
    ],
    [],
    [
      new OrganisationMemberRole("1", "developer"),
      new OrganisationMemberRole("2", "tester"),
      new OrganisationMemberRole("3", "SCRUM master"),
      new OrganisationMemberRole("4", "platform architect"),
    ]
  )

  static ORGANISATION_DETAIL_STATE: OrganisationsState = {
    organisations: new Organisations([]),
    selectedOrganisation: BASF.ORGANISATION,
    selectedOrganisationGroup: undefined,
    selectedOrganisationMember: undefined,
    selectedOrganisationGroupMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }
}

export class Harmonie {
  private static memberCounter = 0;
  static ORGANISATION = new Organisation(
    "6",
    "Harmonie",
    [
      new OrganisationGroup("1", "Muzikanten"),
      new OrganisationGroup("2", "Trommelkorps"),
    ],
    [
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Achtergaele Pieter", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Bekaert Tobias", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Bolssens Bernadette", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Boudein Yolande", true, TEST_AVATAR, [], []),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Brys Ellis", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Cerpentier Christine", true, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Coen Eddy", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Blieck Ivan", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Blieck Robert", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Bock Paul", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Caluwe Adrienne", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Cock Gino", true, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Haeck Theo", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Nys Philip", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Prijcker Koen", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Smet Jonathan", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Smet Leo", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Wilde Sophie", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "De Witte Frans", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Dhondt Wilbert", true, TEST_AVATAR, [new OrganisationMemberRole("5", "Ondervoorzitter")], []),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Goossens Antoine", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Lippens Marian", true, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Maes Dominic", true, TEST_AVATAR, [new OrganisationMemberRole("4", "Secretaris")], []),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Maes Eline", false, TEST_AVATAR, [], ["2"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Marin Wilfried", true, TEST_AVATAR, [new OrganisationMemberRole("1", "Penningmeester")], []),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Martens Etienne", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Meersschaert Romain", true, TEST_AVATAR, [new OrganisationMemberRole("2", "Voorzitter")], []),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Noët Annick", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Pieters Patrick", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Plehiers Tom", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Roggeman Marc", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Schelfhout Jeroen", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Stevens Dirk", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Torfs Patricia", false, TEST_AVATAR, [], ["2"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Bogaert Conny", true, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Bogaert Willy", true, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Daele Hans", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Daele Jasmien", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Damme Ingrid", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Damme Paul", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van der Weken Freddy", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Eetvelde Tinneke", true, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Poeck Mirthe", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Puyvelde Manuella", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Van Steenbergen Marc", true, TEST_AVATAR, [new OrganisationMemberRole("3", "Erevoorzitter")], []),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Vande Sande Youri", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Vansteenkiste Lore", false, TEST_AVATAR, [], ["1", "2"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Verbraeken Luc", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Volleman Walter", true, TEST_AVATAR, [], []),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Wauters André", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Weermann Margriet", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Harmonie.memberCounter++).toString(), "Wuytack Shirley", false, TEST_AVATAR, [], ["1"]),
    ],
    [],
    [
      new OrganisationMemberRole("1", "Penningmeester"),
      new OrganisationMemberRole("2", "Voorzitter"),
      new OrganisationMemberRole("3", "Erevoorzitter"),
      new OrganisationMemberRole("4", "Secretaris"),
      new OrganisationMemberRole("5", "Ondervoorzitter"),
    ],
    [
      "'t Park",
      "Bibliotheek"
    ]
  )

  static ORGANISATION_DETAIL_STATE: OrganisationsState = {
    organisations: new Organisations([]),
    selectedOrganisation: Harmonie.ORGANISATION,
    selectedOrganisationGroup: undefined,
    selectedOrganisationMember: undefined,
    selectedOrganisationGroupMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }
}

export class Toneel {
  private static memberCounter = 0;
  static ORGANISATION = new Organisation(
    "5",
    "Toneel",
    [
      new OrganisationGroup("1", "Acteurs"),
      new OrganisationGroup("2", "Techniek"),
    ],
    [
      new OrganisationMember((Toneel.memberCounter++).toString(), "Ceulemans wesley", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "De Vadder Steven", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Denys Sam", true, TEST_AVATAR, [new OrganisationMemberRole("1", "Werkgroep")], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Embregts Katrien", true, TEST_AVATAR, [new OrganisationMemberRole("2", "Voorzitter")], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Goossens Bart", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Goossens Inne", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Goossens Joke", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Janssens Ivan", false, TEST_AVATAR, [], ["2"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Karnas Indy", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Kint Joris", false, TEST_AVATAR, [], ["2"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Kint Luc", false, TEST_AVATAR, [], ["1", "2"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Maes Dominic", false, TEST_AVATAR, [], ["1", "2"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Maes Eline", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Martine", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Nelissen Ellyn", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Nelissen Luc", true, TEST_AVATAR, [new OrganisationMemberRole("3", "Secretariaat")], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Tavernier Luc", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Torfs Patricia", true, TEST_AVATAR, [new OrganisationMemberRole("1", "Werkgroep")], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Van Kerckhoven Joke", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Van Kerckhoven Raf", false, TEST_AVATAR, [], ["1"]),
      new OrganisationMember((Toneel.memberCounter++).toString(), "Verhaert Nathalie", true, TEST_AVATAR, [new OrganisationMemberRole("4", "Penningmeester")], ["1"]),
    ],
    [],
    [
      new OrganisationMemberRole("1", "Werkgroep"),
      new OrganisationMemberRole("2", "Voorzitter"),
      new OrganisationMemberRole("3", "Secretariaat"),
      new OrganisationMemberRole("4", "Penningmeester"),
    ],
    [
      "SAC BB",
      "Plaasj Kaffee"
    ]
  )

  static ORGANISATION_DETAIL_STATE: OrganisationsState = {
    organisations: new Organisations([]),
    selectedOrganisation: Toneel.ORGANISATION,
    selectedOrganisationGroup: undefined,
    selectedOrganisationMember: undefined,
    selectedOrganisationGroupMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  }
}
