import Reference from "../../domain/Reference";

export class Notifications {
  private filter: string = "";

  constructor(private _notifications: Notification[] = []) {
  }

  setFilter(filter: string): Notifications {
    this.filter = filter.toLowerCase();
    return this;
  }

  get data(): Notification[] {
    return this._notifications.filter((a: Notification) => a.name.toLowerCase().indexOf(this.filter) !== -1);
  }

  get unReadNotificationCount(): number {
    return this.data.filter(n => !n.isRead).length;
  }

  asReferences(): Reference[] {
    return this.data.map(o => new Reference(o.id, o.name));
  }

  byId(ref: any): Notification {
    return this._notifications.filter(o => o.id === ref)[0];
  }

  addNotification(notification: Notification) { //TODO remove when backend gets attached
    this._notifications.push(notification);
  }

  markNewNotificationsRead() {
    this._notifications = this._notifications.map(n => {
      n.setRead();
      return n;
    })
  }
}

export type NotificationId = string

export class Notification {

  constructor(
    private _id: NotificationId,
    private _name: string,
    private _isRead: boolean = false
  ) {
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get isRead(): boolean {
    return this._isRead;
  }

  setRead() {
    this._isRead = true;
  }
}
