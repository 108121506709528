import React, {Component, createRef, RefObject} from 'react';
import {AlertButton, IonItem, IonItemSliding, IonLabel} from '@ionic/react';
import {CalendarEvent} from "../../../data/calendar/domain/CalendarEvent";

interface CalendarItemListItemProps {
  calendarEvent: CalendarEvent;
  onShowAlert: (header: string, buttons: AlertButton[]) => void;
}

class CalendarItemListItem extends Component<CalendarItemListItemProps> {
  render() {
    const ionItemSlidingRef: RefObject<HTMLIonItemSlidingElement> = createRef();
    const {calendarEvent} = this.props;
    const endDate = calendarEvent.date !== calendarEvent.dateEnd ? ` (${calendarEvent.dateEnd})` : '';

    return (
      <IonItemSliding ref={ionItemSlidingRef} class={'track-' + calendarEvent.organisation.name.toLowerCase()}>
        <IonItem routerLink={`/calendar/events/${calendarEvent.id}`}>
          <IonLabel>
            <h3>{calendarEvent.name}</h3>
            <p>
              {calendarEvent.timeStart}&nbsp;&mdash;&nbsp;
              {calendarEvent.timeEnd}{endDate}&nbsp;&nbsp;{calendarEvent.location}<br/>
              <small>{calendarEvent.groups.map(g => g.name).join(", ")}</small><br/>
              <small>{calendarEvent.comments}</small>
            </p>
          </IonLabel>
        </IonItem>
      </IonItemSliding>
    );
  }
}

export default React.memo(CalendarItemListItem);
