import React, {Component} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons, IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';

import {RouteComponentProps} from "react-router";

import {connect} from "../../data/connect";
import logger from "../../util/logger";
import DataContainer from "../components/DataContainer";
import {editOrganisationGroupMember, EditOrganisationGroupMemberRequest, loadOrganisationGroupMember} from "../../data/organisations/organisations.actions";
import {OrganisationGroupMemberRoles} from "../../data/organisations/domain/OrganisationGroupMemberRole";
import {OrganisationGroupMember} from "../../data/organisations/domain/OrganisationGroupMember";
import {Organisation} from "../../data/organisations/domain/Organisation";
import {OrganisationGroup} from "../../data/organisations/domain/OrganisationGroup";

interface SaveOrganisationGroupMemberProps extends RouteComponentProps<{ organisationId: string, organisationGroupId: string, organisationGroupMemberId?: string }, {}, { organisationName?: string, organisationGroupName?: string }> {
  editOrganisationGroupMember: typeof editOrganisationGroupMember;
  loadOrganisationGroupMember: typeof loadOrganisationGroupMember;
  organisation?: Organisation;
  organisationGroup?: OrganisationGroup;
  organisationGroupMember?: OrganisationGroupMember;
  isOrganisationGroupMemberLoading: boolean;
}

interface SaveOrganisationGroupMemberState {
  dataLoaded: boolean;
  loadTriggered: boolean;
  isLoading: boolean;
  title: string;
  submitButtonText: string;
  organisationName?: string;
  form: {
    isReponsible?: boolean,
    memberRoles?: OrganisationGroupMemberRoles
  }
}

class SaveOrganisationGroupMember extends Component<SaveOrganisationGroupMemberProps, SaveOrganisationGroupMemberState> {

  constructor(props: SaveOrganisationGroupMemberProps) {
    super(props);
    this.state = {
      dataLoaded: false,
      loadTriggered: false,
      isLoading: false,
      title: "",
      submitButtonText: "",
      organisationName: undefined,
      form: {
        isReponsible: undefined,
        memberRoles: undefined
      }
    }
  }

  ionViewWillEnter() {
    const organisationId = this.props.match.params.organisationId;
    const organisationGroupId = this.props.match.params.organisationGroupId;
    if (this.state.loadTriggered) {
      logger.info("save organisation group member already visible");
    } else {
      logger.info("show save organisation group member", organisationId);
      const organisationGroupMemberId = this.props.match.params.organisationGroupMemberId;
      const isEdit = organisationGroupMemberId !== undefined;
      let title: string, submitButtonText: string;
      if (isEdit) {
        title = `Update organisation group member`;
        submitButtonText = 'SAVE';
        this.props.loadOrganisationGroupMember(organisationId, organisationGroupId, organisationGroupMemberId!!);
      } else { //create
        const organisationName = this.props.location.state.organisationName;
        const organisationGroupName = this.props.location.state.organisationGroupName;
        if (!organisationName) {
          console.error('organisationName is not filled');
          this.setState({...this.state, loadTriggered: false});
          this.props.history.push(`/organisations/${organisationId}/groups/${organisationGroupId}`);
        } else if (!organisationGroupName) {
          console.error('organisationGroupName is not filled');
          this.setState({...this.state, loadTriggered: false});
          this.props.history.push(`/organisations/${organisationId}/groups/${organisationGroupId}`);
        } else {
          title = `Create new organisation group for organisation ${organisationName}`;
          submitButtonText = 'CREATE';
        }
      }
      this.setState({
        ...this.state,
        dataLoaded: false,
        loadTriggered: true,
        isLoading: false,
        title: title!!,
        submitButtonText: submitButtonText!!
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<SaveOrganisationGroupMemberProps>, prevState: Readonly<SaveOrganisationGroupMemberState>, snapshot?: any) {
    const member = this.props.organisationGroupMember;
    if (!this.state.dataLoaded && member !== undefined) {
      this.setState({
        ...this.state,
        dataLoaded: true,
        form: {
          isReponsible: member.isResponsible,
          memberRoles: new OrganisationGroupMemberRoles(member.roles.data)
        }
      });
    }
  }

  ionViewWillLeave() {
    logger.info("hide save organisation");
    this.setState({loadTriggered: false});
  }

  render() {
    return (
      <IonPage id="save-organisation-group-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton/>
            </IonButtons>
            <IonTitle>{this.state.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.renderContent(this.isLoading() || !this.state.dataLoaded)}
        </IonContent>
      </IonPage>
    )
  }

  private isLoading() {
    logger.debug("is save organisation group member loading? ", this.state.isLoading, this.props.isOrganisationGroupMemberLoading, this.props.organisationGroupMember, this.props.organisation, this.props.organisationGroup)
    return this.state.isLoading
      || this.props.isOrganisationGroupMemberLoading
      || this.props.organisationGroupMember === undefined
      || this.props.organisation === undefined
      || this.props.organisationGroup === undefined;
  }

  renderContent(isLoading: boolean) {
    const editMember = () => {
      const request: EditOrganisationGroupMemberRequest = {
        organisationId: this.props.match.params.organisationId,
        organisationGroupId: this.props.match.params.organisationGroupId,
        organisationGroupMemberId: this.props.match.params.organisationGroupMemberId!!,
        isResponsible: this.state.form.isReponsible || false,
        roles: this.state.form.memberRoles!!
      };
      this.props.editOrganisationGroupMember(request,
        () => {
          logger.info("organisation member edited", request);
          this.props.history.push(`/organisations/${request.organisationId}/groups/${request.organisationGroupId}`);
        },
        (reason: any) => {
          logger.error("something went wrong while editing organisation member", reason);
          //TODO error handling
        });
    }

    return (
      <DataContainer
        isLoading={isLoading}
        contentRender={() => {
          return (
            <div className="content-container">
              <IonList>
                <IonListHeader>Basic info</IonListHeader>
                <IonItem>
                  <IonLabel position="floating" color="primary">Organisation name</IonLabel>
                  <IonInput value={this.props.organisation!!.name} disabled>&nbsp;</IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating" color="primary">Organisation group name</IonLabel>
                  <IonInput value={this.props.organisationGroup!!.name} disabled>&nbsp;</IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating" color="primary">Member name</IonLabel>
                  <IonInput value={this.props.organisationGroupMember!!.name} disabled>&nbsp;</IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel color="primary">Is responsible?</IonLabel>
                  <IonToggle checked={this.state.form.isReponsible}
                             onIonChange={e => {
                               this.setState({
                                 ...this.state,
                                 form: {...this.state.form, isReponsible: e.detail.checked}
                               })
                             }}/>
                </IonItem>
              </IonList>
              <IonList>
                <IonListHeader>Roles</IonListHeader>
                {this.props.organisationGroup!!.availableMemberRoles.data.map(amr => {
                  return (
                    <IonItem key={amr.id}>
                      <IonLabel color="primary">{amr.name}</IonLabel>
                      <IonCheckbox key={amr.id}
                                   slot="end"
                                   color="primary"
                                   checked={this.state.form.memberRoles!!.containsId(amr.id)}
                                   onIonChange={e => this.changeRoleState(e.detail.checked, amr.id)}
                      >&nbsp;</IonCheckbox>
                    </IonItem>
                  )
                })}
              </IonList>
              <IonRow>
                <IonCol>
                  <IonButton type="submit" expand="block" onClick={editMember}>{this.state.submitButtonText}</IonButton>
                </IonCol>
              </IonRow>
            </div>
          )
        }}
      />
    )
  }

  private changeRoleState(isChecked: boolean, roleId: string) {
    const memberRoles = this.state.form.memberRoles!!;
    const availableMemberRoles = this.props.organisationGroup!!.availableMemberRoles;

    let newMemberRoles;
    if (isChecked && !memberRoles.containsId(roleId)) {
      newMemberRoles = memberRoles.data;
      newMemberRoles.push(availableMemberRoles.getById(roleId));
    } else if (!isChecked && memberRoles.containsId(roleId)) {
      const index = memberRoles.data.map(r => r.id).indexOf(roleId);
      newMemberRoles = memberRoles.data.slice(index, 1);
    }

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        memberRoles: new OrganisationGroupMemberRoles(newMemberRoles)
      }
    })
  }

}

export default connect<SaveOrganisationGroupMemberProps>({
  mapStateToProps: (state) => ({
    organisation: state.organisationsState.selectedOrganisation,
    organisationGroup: state.organisationsState.selectedOrganisationGroup,
    organisationGroupMember: state.organisationsState.selectedOrganisationGroupMember,
    isOrganisationGroupMemberLoading: state.organisationsState.isOrganisationGroupMemberLoading
  }),
  mapDispatchToProps: {
    editOrganisationGroupMember,
    loadOrganisationGroupMember
  },
  component: withIonLifeCycle(SaveOrganisationGroupMember)
})
