import React, {Component} from 'react';
import {IonSpinner} from "@ionic/react";

import "./DataContainer.scss"

interface DataContainerProps {
  isLoading: boolean;
  contentRender: () => any
}

interface DataContainerState {
}

class DataContainer extends Component<DataContainerProps, DataContainerState> {

  render() {
    return (
      <div id={"data-container"} className={`content-container ${this.props.isLoading ? 'is-loading' : 'loaded'}`}>
        {this.renderSpinner(this.props.isLoading)}
        {this.renderContent(this.props.isLoading)}
      </div>
    );
  }

  renderContent(isLoading: boolean){
    if(isLoading){
      return null;
    } else {
      return this.props.contentRender();
    }
  }

  renderSpinner(isLoading: boolean) {
    if(isLoading){
      return <IonSpinner name="bubbles"/>
    } else {
      return null;
    }
  }

}

export default DataContainer;
