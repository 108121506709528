import React, {Component} from "react";
import {IonInput, IonItem, IonLabel} from "@ionic/react";
import {FormValidator, ValidatedElement} from "../../util/FormValidator";
import "./FormInput.scss";

interface Props {
  label: string;
  required: boolean;
  value: string | undefined
  onChange: (newValue: string | undefined) => void;
  validator: FormValidator;
  disabled?: boolean;
}

interface State {
  inError: boolean;
  value: string | undefined;
}

export default class TextInput extends Component<Props, State> implements ValidatedElement {

  constructor(props: Props) {
    super(props);
    this.props.validator.registerValidatedElement(this);
    this.state = {
      inError: false,
      value: props.value
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.state.inError && this.props.disabled) {
      this.clearErrors();
    }
  }

  render() {
    const {label, value, onChange, required} = this.props;
    const {inError} = this.state;

    return (
      <div>
        <IonItem className={`input-item text-input-item ${inError ? 'in-error' : ''}`}>
          <IonLabel className={"label"} position="floating" color="primary">{label}{required ? '*' : ''}</IonLabel>
          <IonInput value={value}
                    onIonChange={e => {
                      onChange(e.detail.value || undefined);
                      this.setState({value: e.detail.value || undefined});
                      this.clearErrors();
                    }}>&nbsp;</IonInput>
        </IonItem>
        {inError && <IonLabel position="fixed" className={"error-message"}>This field is required.</IonLabel>}
      </div>
    )
  }

  validate(): boolean {
    const inError = !this.props.disabled && this.props.required && (!this.state.value || this.state.value.length === 0);
    this.setState({inError: inError});
    return !inError;
  }

  clearErrors(): void {
    this.setState({inError: false});
  }
}
