import {CalendarDay} from "../domain/CalendarDay";
import {CalendarEvent} from "../domain/CalendarEvent";
import Reference from "../../domain/Reference";
import {CalendarState} from "../calendar.state";
import {CalendarData} from "../domain/CalendarData";

export class StaticCalenderData {
  private static eventCounter = 0;
  static calendarItemCounter = 1000;
  static DEFAULT: CalendarState = {

    calendar2: new CalendarData([]),
    calendar: new CalendarData(
      [
        new CalendarDay(
          "2020-08-22",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-08-22",
              "2020-08-22",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 4-18"
            )
          ]
        ),
        new CalendarDay(
          "2020-08-27",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-08-27",
              "2020-08-27",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 14-36"
            )
          ]
        ),
        new CalendarDay(
          "2020-08-29",
          [
            new CalendarEvent(
              "2020-08-29",
              "2020-08-29",
              (StaticCalenderData.eventCounter++).toString(),
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 36-54"
            )
          ]
        ),
        new CalendarDay(
          "2020-09-04",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-09-04",
              "2020-09-04",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 54-74"
            )
          ]
        ),
        new CalendarDay(
          "2020-09-05",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-09-05",
              "2020-09-05",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "volledig"
            )
          ]
        ),
        new CalendarDay(
          "2020-09-10",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-09-10",
              "2020-09-10",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 4-18"
            )
          ]
        ),
        new CalendarDay(
          "2020-09-12",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-09-12",
              "2020-09-12",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 23-44"
            )
          ]
        ),
        new CalendarDay(
          "2020-09-17",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-09-17",
              "2020-09-17",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 36-54"
            )
          ]
        ),
        new CalendarDay(
          "2020-09-22",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-09-22",
              "2020-09-22",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 18-23 en 54-74"
            )
          ]
        ),
        new CalendarDay(
          "2020-09-24",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-09-24",
              "2020-09-24",
              "20:00",
              "22:00",
              "Repetitie",
              "",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 21-39"
            )
          ]
        ),
        new CalendarDay(
          "2020-09-26",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-09-26",
              "2020-09-26",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 4-23"
            )
          ]
        ),
        new CalendarDay(
          "2020-10-03",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-10-03",
              "2020-10-03",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 36-54"
            )
          ]
        ),
        new CalendarDay(
          "2020-10-09",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-10-09",
              "2020-10-09",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 54-74"
            )
          ]
        ),
        new CalendarDay(
          "2020-10-10",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-10-10",
              "2020-10-10",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 36-74"
            )
          ]
        ),
        new CalendarDay(
          "2020-10-15",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-10-15",
              "2020-10-15",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 4-18"
            )
          ]
        ),
        new CalendarDay(
          "2020-10-17",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-10-17",
              "2020-10-17",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 18-36"
            )
          ]
        ),
        new CalendarDay(
          "2020-10-22",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-10-22",
              "2020-10-22",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 36-54"
            )
          ]
        ),
        new CalendarDay(
          "2020-10-24",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-10-24",
              "2020-10-24",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 54-74"
            )
          ]
        ),
        new CalendarDay(
          "2020-10-27",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-10-27",
              "2020-10-27",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 54-74"
            )
          ]
        ),
        new CalendarDay(
          "2020-10-29",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-10-29",
              "2020-10-29",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 4-36"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-06",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-06",
              "2020-11-06",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 4-44 (voor pauze)"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-07",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-07",
              "2020-11-07",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 45-74 (na pauze)"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-09",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-09",
              "2020-11-09",
              "09:00",
              "16:00",
              "Technische opbouw podium",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("2", "Techniek")],
              "9u middenschool, 9u30 bovenbouw"
            ),
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-09",
              "2020-11-09",
              "11:00",
              "14:00",
              "Bestuursvergadering",
              "Plaasj Kaffee",
              new Reference("5", "Toneel"),
              [new Reference("3", "Bestuur")],
              undefined
            )
          ]
        ),
        new CalendarDay(
          "2020-11-12",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-12",
              "2020-11-12",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 4-44 (voor pauze)"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-14",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-14",
              "2020-11-14",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "p. 45-74 (na pauze)"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-16",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-16",
              "2020-11-16",
              "11:00",
              "15:00",
              "Technische opbouw zaal",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("2", "Techniek")],
              undefined
            )
          ]
        ),
        new CalendarDay(
          "2020-11-19",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-19",
              "2020-11-19",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "volledig"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-21",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-21",
              "2020-11-21",
              "20:00",
              "22:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "volledig"
            ),
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-21",
              "2020-11-21",
              "14:00",
              "17:00",
              "Opbouw tent Bibliotheek",
              "Bibliotheek",
              new Reference("6", "Harmonie"),
              [new Reference("1", "Muzikanten"), new Reference("3", "Bestuur")],
              undefined
            )
          ]
        ),
        new CalendarDay(
          "2020-11-22",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-22",
              "2020-11-22",
              "20:00",
              "22:00",
              "Generale repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "generale"
            ),
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-22",
              "2020-11-22",
              "19:00",
              "04:00",
              "Ceciliafeest",
              "Bibliotheek",
              new Reference("6", "Harmonie"),
              [new Reference("1", "Muzikanten"), new Reference("2", "Trommelkorps"), new Reference("3", "Bestuur")],
              undefined
            )
          ]
        ),
        new CalendarDay(
          "2020-11-23",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-23",
              "2020-11-23",
              "20:15",
              "23:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "première avond"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-24",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-24",
              "2020-11-24",
              "15:15",
              "18:00",
              "Voorstelling",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "voorstellig matiné"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-28",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-28",
              "2020-11-28",
              "20:00",
              "22:00",
              "Raccord",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              undefined
            )
          ]
        ),
        new CalendarDay(
          "2020-11-29",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-29",
              "2020-11-29",
              "20:15",
              "23:00",
              "Voorstelling",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "voorstelling avond"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-30",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-30",
              "2020-11-30",
              "20:15",
              "23:00",
              "Repetitie",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "voorstelling avond"
            ),
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-30",
              "2020-11-30",
              "09:00",
              "14:00",
              "Begeleiding Intrede Sint",
              "Bibliotheek",
              new Reference("6", "Harmonie"),
              [new Reference("1", "Muzikanten"), new Reference("2", "Trommelkorps")],
              "Programma volgt"
            )
          ]
        ),
        new CalendarDay(
          "2020-12-01",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-12-01",
              "2020-12-01",
              "20:00",
              "22:00",
              "Afbraak",
              "SAC BB",
              new Reference("5", "Toneel"),
              [new Reference("1", "Jos wil naar Kos")],
              "afbraak"
            ),
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-12-01",
              "2020-12-01",
              "10:00",
              "16:00",
              "Cecilia-rondgang",
              "t Park",
              new Reference("6", "Harmonie"),
              [new Reference("1", "Muzikanten"), new Reference("2", "Trommelkorps"), new Reference("3", "Bestuur")],
              "Verzamelen 9u30 lokaal"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-11",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-11",
              "2020-11-11",
              "08:40",
              "13:00",
              "Wapenstilstand herdenking",
              "t Park",
              new Reference("6", "Harmonie"),
              [new Reference("1", "Muzikanten"), new Reference("2", "Trommelkorps"), new Reference("3", "Bestuur")],
              "Start Pools monument"
            )
          ]
        ),
        new CalendarDay(
          "2020-11-12",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-12",
              "2020-11-12",
              "20:00",
              "22:00",
              "Bestuursvergadering",
              "t Park",
              new Reference("6", "Harmonie"),
              [new Reference("3", "Bestuur")],
              undefined
            )
          ]
        ),
        new CalendarDay(
          "2020-11-26",
          [
            new CalendarEvent(
              (StaticCalenderData.eventCounter++).toString(),
              "2020-11-26",
              "2020-11-26",
              "09:00",
              "21:00",
              "Folkloredag",
              "t Park",
              new Reference("6", "Harmonie"),
              [new Reference("4", "Maandagsvrienden")],
              "Programma volgt"
            )
          ]
        )
      ]
    ),
    isCalendarLoading: false,
    isEventLoading: false,
    selectedEvent: undefined
  }
}
