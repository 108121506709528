import {OrganisationsState} from "./organisations.state";
import {OrganisationsActions} from "./organisations.actions";

export const organisationsReducer = (state: OrganisationsState, action: OrganisationsActions): OrganisationsState => {
  switch (action.type) {
    case 'set-organisations-loading': {
      return {...state, areOrganisationsLoading: action.isLoading};
    }
    case 'set-organisation-loading': {
      return {...state, isOrganisationLoading: action.isLoading};
    }
    case 'set-organisation-group-loading': {
      return {...state, isOrganisationGroupLoading: action.isLoading};
    }
    case 'set-organisation-member-loading': {
      return {...state, isOrganisationMemberLoading: action.isLoading};
    }
    case 'set-organisation-group-member-loading': {
      return {...state, isOrganisationGroupMemberLoading: action.isLoading};
    }
    case 'set-organisations-data': {
      return {...state, ...action.data};
    }
  }
}
