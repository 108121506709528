import React, {Component, createRef, RefObject} from 'react';
import {
  getConfig,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonTitle,
  IonToast,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';

import {RouteComponentProps} from "react-router";

import "./Calendar.scss"
import {connect} from "../../data/connect";
import logger from "../../util/logger";
import {addOutline, options, search} from "ionicons/icons";
import {setSearchText} from '../../data/sessions/sessions.actions';
import CalendarItemList from "./components/CalendarItemList";
import DataContainer from "../components/DataContainer";
import {loadCalendar} from "../../data/calendar/calendar.actions";
import CalendarItemListFilter from "./components/CalendarItemListFilter";
import {OrganisationId, Organisations} from "../../data/organisations/domain/Organisation";
import {loadUserOrganisations} from "../../data/organisations/organisations.actions";
import Reference from "../../data/domain/Reference";
import {CalendarData} from "../../data/calendar/domain/CalendarData";
import {scrollToElement} from "../../util/functions";
import ViaeDateUtils from "../../util/ViaeDate.utils";

interface CalendarProps extends RouteComponentProps<{}> {
  mode: 'ios' | 'md';
  calendarData?: CalendarData;
  organisations?: Organisations;
  isCalendarLoading: boolean;
  loadCalendar: typeof loadCalendar;
  loadUserOrganisations: typeof loadUserOrganisations;
}

interface CalendarState {
  loadTriggered: boolean;
  showSearchbar: boolean;
  showFilterModal: boolean;
  showCompleteToast: boolean;
  segment: 'all';
  selectedCalendarOrganisationIds?: OrganisationId[];
}

class Calendar extends Component<CalendarProps, CalendarState> {
  private ionRefresherRef: RefObject<HTMLIonRefresherElement>;
  private pageRef: RefObject<HTMLElement>;

  constructor(props: CalendarProps) {
    super(props);
    this.ionRefresherRef = createRef();
    this.pageRef = createRef();

    this.state = {
      loadTriggered: false,
      showSearchbar: false,
      showFilterModal: false,
      showCompleteToast: false,
      segment: 'all',
      selectedCalendarOrganisationIds: undefined
    }
  }

  ionViewWillEnter() {
    if (this.state.loadTriggered) {
      logger.info("calendar already loaded");
    } else {
      logger.info("load calendar");
      this.props.loadCalendar(() => logger.info("calendar got loaded"));
      this.props.loadUserOrganisations();
      this.setState({loadTriggered: true});
      this.scrollToToday();
    }
  }

  ionViewWillLeave() {
    logger.info("reset calendar load trigger");
    this.setState({loadTriggered: false})
  }

  shouldComponentUpdate(nextProps: Readonly<CalendarProps>, nextState: Readonly<CalendarState>, nextContext: any): boolean {
    return this.state.loadTriggered && (
      nextProps !== this.props || JSON.stringify(nextState) !== JSON.stringify(this.state)
    )
  }

  componentDidUpdate(prevProps: Readonly<CalendarProps>, prevState: Readonly<CalendarState>, snapshot?: any) {
    if (prevProps.calendarData === undefined && this.props.calendarData !== undefined && this.state.loadTriggered) {
      this.scrollToToday();
    }
  }

  private scrollToToday() {
    const today = ViaeDateUtils.nowAsBackendFormattedString();
    logger.info("scroll to data of today", today);
    scrollToElement(today);
  }

  render() {
    logger.debug("rendering calendar", this.props.isCalendarLoading, this.props.calendarData, this.props.organisations);
    const isLoading = this.props.isCalendarLoading || this.props.calendarData === undefined || this.props.organisations === undefined

    const doRefresh = () => {
      setTimeout(() => {
        this.ionRefresherRef.current!.complete();
        this.setState({showCompleteToast: true});
      }, 2500)
    };

    const ios = this.props.mode === 'ios';
    const showSearchbar = this.state.showSearchbar && !isLoading;
    return (
      <IonPage id="calendar-page">
        <IonHeader translucent={true}>
          <IonToolbar>
            {!showSearchbar &&
            <IonButtons slot="start">
              <IonMenuButton/>
            </IonButtons>
            }
            {!ios && !showSearchbar &&
            <IonTitle>Calendar</IonTitle>
            }
            {showSearchbar &&
            <IonSearchbar showCancelButton="always" placeholder="Search" onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
                          onIonCancel={() => this.setState({showSearchbar: false})}>&nbsp;</IonSearchbar>
            }

            <IonButtons slot="end">
              <IonButton onClick={() => this.scrollToToday()}>
                TODAY
              </IonButton>
              {!ios && !showSearchbar &&
              <IonButton onClick={() => this.setState({showSearchbar: true})}>
                <IonIcon slot="icon-only" icon={search}>&nbsp;</IonIcon>
              </IonButton>
              }
              {!showSearchbar &&
              <IonButton onClick={() => this.setState({showFilterModal: true})}>
                {this.props.mode === 'ios' ? 'Filter' : <IonIcon icon={options} slot="icon-only"/>}
              </IonButton>
              }
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent id={"calendar-data-container"} slot="fixed" scrollY={true}>
          <DataContainer
            isLoading={isLoading}
            contentRender={() => {
              this.props.calendarData!!.setOrganisationFilter(this.state.selectedCalendarOrganisationIds || this.props.organisations!!.data.map(o => o.id));
              return (
                <div>
                  <IonHeader collapse="condense">
                    <IonToolbar>
                      <IonTitle size="large">Schedule</IonTitle>
                    </IonToolbar>
                    <IonToolbar>
                      <IonSearchbar placeholder="Search" onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}>&nbsp;</IonSearchbar>
                    </IonToolbar>
                  </IonHeader>

                  <IonRefresher slot="fixed" ref={this.ionRefresherRef} onIonRefresh={doRefresh}>
                    <IonRefresherContent/>
                  </IonRefresher>

                  <IonToast
                    isOpen={this.state.showCompleteToast}
                    message="Refresh complete"
                    duration={2000}
                    onDidDismiss={() => this.setState({showCompleteToast: false})}
                  />

                  <CalendarItemList
                    calendarData={this.props.calendarData!!}
                  />
                </div>
              )
            }}
          />
        </IonContent>

        <IonModal
          isOpen={this.state.showFilterModal}
          onDidDismiss={() => this.setState({showFilterModal: false})}
          swipeToClose={true}
          presentingElement={this.pageRef.current!}
          cssClass="session-list-filter"
        >
          <CalendarItemListFilter
            onDismissModal={() => this.setState({showFilterModal: false})}
            availableOrganisations={this.props.organisations!!.data.map(o => new Reference(o.id, o.name))}
            selectedOrganisations={this.state.selectedCalendarOrganisationIds || this.props.organisations!!.data.map(o => o.id)}
            onCalendarItemOrganisationFilterChanged={(ids) => this.setState({selectedCalendarOrganisationIds: ids})}
          />
        </IonModal>

        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton>
            <IonIcon icon={addOutline}
                     onClick={() => this.props.history.push(`/calendar/events/new`)}
            />
          </IonFabButton>
        </IonFab>

      </IonPage>
    );
  }

}

export default connect<CalendarProps>({
  mapStateToProps: (state) => ({
    calendarData: state.calendarState.calendar,
    organisations: state.organisationsState.organisations,
    isCalendarLoading: state.calendarState.isCalendarLoading,
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
    loadCalendar,
    loadUserOrganisations,
  },
  component: withIonLifeCycle(Calendar)
})
