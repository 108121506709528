import {combineReducers} from './combineReducers';
import {sessionsReducer} from './sessions/sessions.reducer';
import {userReducer} from './user/user.reducer';
import {organisationsReducer} from "./organisations/organisations.reducer";
import {Organisations} from "./organisations/domain/Organisation";
import {CalendarReducer} from "./calendar/calendar.reducer";
import {notificationsReducer} from "./notifications/notifications.reducer";
import {Notifications} from "./notifications/domain/Notification";

export const initialState: AppState = {
  data: {
    schedule: {groups: []} as any,
    sessions: [],
    speakers: [],
    favorites: [],
    locations: [],
    allTracks: [],
    filteredTracks: [],
    mapCenterId: 0,
    loading: false,
    menuEnabled: true
  },
  user: {
    hasSeenTutorial: false,
    darkMode: false,
    isLoggedin: false,
    loading: false
  },
  organisationsState: {
    organisations: new Organisations(),
    selectedOrganisation: undefined,
    selectedOrganisationGroup: undefined,
    selectedOrganisationMember: undefined,
    selectedOrganisationGroupMember: undefined,
    areOrganisationsLoading: false,
    isOrganisationLoading: false,
    isOrganisationGroupLoading: false,
    isOrganisationMemberLoading: false,
    isOrganisationGroupMemberLoading: false
  },
  calendarState: {
    calendar: undefined,
    isCalendarLoading: false,
    isEventLoading: false,
    selectedEvent: undefined
  },
  notificationsState: {
    areNotificationsLoading: false,
    notifications: new Notifications()
  }
};

export const reducers = combineReducers({
  data: sessionsReducer,
  user: userReducer,
  organisationsState: organisationsReducer,
  calendarState: CalendarReducer,
  notificationsState: notificationsReducer
});

export type AppState = ReturnType<typeof reducers>;
