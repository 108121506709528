export class Messages {
  constructor(
    private _messages: Message[] = []
  ) {
  }

  get messages(): Message[] {
    return this._messages;
  }
}

export class Message {
  constructor(
    private _id: string,
    private _message: string,
    private _created: Date,
    private _from: string,
    private _fromAvatar: string
  ) {
  }

  get id(): string {
    return this._id;
  }

  get message(): string {
    return this._message;
  }

  get created(): Date {
    return this._created;
  }

  get from(): string {
    return this._from;
  }

  get fromAvatar(): string {
    return this._fromAvatar;
  }
}
