import {CalendarDay, CalendarDays} from "./CalendarDay";
import {OrganisationId} from "../../organisations/domain/Organisation";
import {CalendarEvent, CalendarEventId} from "./CalendarEvent";

export class CalendarData {
  private _days: CalendarDays;

  constructor(_days: CalendarDay[]) {
    this._days = new CalendarDays(_days)
  }

  get days(): CalendarDays {
    return this._days;
  }

  setOrganisationFilter(organisationIds: OrganisationId[] | undefined) {
    this.days.setOrganisationFilter(organisationIds);
  }

  hasDayDataFor(date: string) {
    return this.days.data.filter(d => d.date === date).length > 0;
  }

  getDay(date: string):CalendarDay {
    return this._days.getDay(date);
  }

  addDay(calendarDay: CalendarDay) {
    this._days.addDay(calendarDay);
  }

  getEventById(eventId: CalendarEventId): CalendarEvent {
    return this._days.getEventById(eventId);
  }
}



