import React, {Component} from "react";
import {IonIcon, IonItem, IonLabel, IonList, IonPage, IonSearchbar} from "@ionic/react";
import {SearchbarChangeEventDetail} from "@ionic/core";
import {eyeOutline, pencilOutline} from "ionicons/icons";
import "./Listing.scss"

export interface ListingByNameItem {
  name: string;
  onView?: () => void;
  onEdit?: () => void;
}

interface ListingByNameProps {
  items: ListingByNameItem[];
  onSearchNeedleChanged: (newValue: string) => void;
}

interface ListingByNameState {
  searchNeedle: string;
}

export default class ListingByName extends Component<ListingByNameProps, ListingByNameState> {
  constructor(props: ListingByNameProps) {
    super(props);
    this.state = {
      searchNeedle: ""
    }
  }

  render() {
    return (
      <IonList className={"listing-component"}>
        <IonSearchbar
          value={this.state.searchNeedle}
          onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>) => {
            const newValue = e.detail.value!;
            this.setState({searchNeedle: newValue});
            this.props.onSearchNeedleChanged(newValue);
          }}
          onIonClear={() => {
            this.setState({searchNeedle: ""});
            this.props.onSearchNeedleChanged("");
          }}
        >
          &nbsp;
        </IonSearchbar>
        {this.props.items.map((item, index) => this.renderItem(item, index))}
      </IonList>
    )
  }

  private renderItem(item: ListingByNameItem, index: number) {
    const existingActions = [item.onView, item.onEdit].filter(a => a !== undefined)
    let defaultAction;
    if(existingActions.length === 1){
      defaultAction = existingActions[0];
    } else {
      defaultAction = () => {}
    }

    return (
      <IonItem key={index}
               className={"hoverable list-item-with-icon"}
               onClick={defaultAction}
      >
        <IonLabel className={"label"}>
          <span className={"text"}>{item.name}</span>
        </IonLabel>
        {item.onView && <IonIcon onClick={item.onView} className={"icon"} icon={eyeOutline} slot={"end"}>&nbsp;</IonIcon>}
        {item.onEdit && <IonIcon onClick={item.onEdit} className={"icon"} icon={pencilOutline} slot={"end"}>&nbsp;</IonIcon>}
      </IonItem>
    );
  }
}
