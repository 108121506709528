import {CalendarEvent, CalendarEventId, CalendarEvents} from "./CalendarEvent";
import {OrganisationId} from "../../organisations/domain/Organisation";

interface CalendarDayFilter {
}

const DEFAULT_FILTER = {};

export class CalendarDays {

  constructor(private _days: CalendarDay[] = [], private filter: CalendarDayFilter = DEFAULT_FILTER) {
  }

  clearFilter() {
    this.filter = DEFAULT_FILTER;
    return this;
  }

  get data(): CalendarDay[] {
    return this._days
      .filter(d => d.events.length > 0)
      .sort((d1, d2) => d1.date.localeCompare(d2.date));
  }

  get length(): number {
    return this.data.length;
  }

  get single(): CalendarDay {
    if (this.data.length === 1) {
      return this.data[0];
    } else {
      throw Error("Not one single result for calendar day list: " + this.data.map(g => g.date).join(','))
    }
  }

  setOrganisationFilter(organisationIds: OrganisationId[] | undefined) {
    this._days.forEach(day => day.setOrganisationFilter(organisationIds))
    return this;
  }

  addDay(calendarDay: CalendarDay) {
    this._days.push(calendarDay);
  }

  getDay(date: string):CalendarDay {
    return this._days.filter(d => d.date === date)[0];
  }

  getEventById(eventId: CalendarEventId): CalendarEvent {
    return this._days.flatMap(d => d.events.data).filter(e => e.id === eventId)[0];
  }
}

export class CalendarDay {
  private _events: CalendarEvents;

  constructor(private _date: string,
              _events: CalendarEvent[]) {
    this._events = new CalendarEvents(_events)
  }

  get date(): string {
    return this._date;
  }

  get events(): CalendarEvents {
    return this._events;
  }

  setOrganisationFilter(organisationIds: OrganisationId[] | undefined) {
    this._events.setOrganisationFilter(organisationIds);
  }

  addEvent(calendarEvent: CalendarEvent) {
    this._events.addEvent(calendarEvent);
  }

  deleteEvent(eventId: string) {
    this._events.deleteEvent(eventId);
  }
}



