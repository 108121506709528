import React, {Component} from 'react';
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonList,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';

import {RouteComponentProps} from "react-router";
import {addOutline, chatboxOutline, listOutline, mapOutline, peopleOutline, pricetagsOutline, ribbonOutline} from "ionicons/icons";

import "./OrganisationDetail.scss"
import {loadUserOrganisation} from "../../data/organisations/organisations.actions";
import {connect} from "../../data/connect";
import logger from "../../util/logger";
import DataContainer from "../components/DataContainer";
import {Organisation} from "../../data/organisations/domain/Organisation";
import ListingByName from "../components/ListingByName";
import TabBar from "../components/TabBar";
import {TEST_AVATAR} from "../../data/avatars";
import ListingByAvatarTitleAndSubtitle from "../components/ListingByAvatarTitleAndSubtitle";
import ErrorMessage from "../components/ErrorMessage";

interface OrganisationDetailProps extends RouteComponentProps<{ organisationId: string }> {
  loadUserOrganisation: typeof loadUserOrganisation;
  selectedOrganisation: Organisation;
  isOrganisationLoading: boolean;
}

interface OrganisationDetailState {
  groupSearchNeedle: string;
  boardSearchNeedle: string;
  memberSearchNeedle: string;
  roleSearchNeedle: string;
  locationSearchNeedle: string;
  loadTriggered: boolean;
  activeView: 'groups' | 'messages' | 'board' | 'members' | 'boardroles' | 'locations';
  organisationNotFoundError: boolean;
}

class OrganisationDetail extends Component<OrganisationDetailProps, OrganisationDetailState> {

  constructor(props: OrganisationDetailProps) {
    super(props);
    this.state = {
      groupSearchNeedle: "",
      boardSearchNeedle: "",
      memberSearchNeedle: "",
      roleSearchNeedle: "",
      locationSearchNeedle: "",
      loadTriggered: false,
      activeView: 'groups',
      organisationNotFoundError: false,
    }
  }

  ionViewWillEnter() {
    const organisationId = this.props.match.params.organisationId;
    if (this.state.loadTriggered) {
      logger.info("user organisation already loaded", organisationId);
    } else {
      logger.info("load user organisation", organisationId);
      this.props.loadUserOrganisation(organisationId, () => this.setState({...this.state, organisationNotFoundError: true}));
      this.setState({loadTriggered: true})
    }
  }

  ionViewWillLeave() {
    logger.info("reset user organisation load trigger");
    this.setState({loadTriggered: false})
  }

  render() {
    const isLoading = this.props.isOrganisationLoading || this.props.selectedOrganisation === undefined
    const {selectedOrganisation} = this.props;

    if (this.state.organisationNotFoundError) {
      return <ErrorMessage text={`Organisation with id ${this.props.match.params.organisationId} could not be found`}/>
    }

    return (
      <IonPage id="organisation-detail-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton/>
            </IonButtons>
            <IonTitle>{`Organisation Detail ${isLoading ? '' : `: ${selectedOrganisation!.name}`}`}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.renderContent(isLoading, selectedOrganisation)}
        </IonContent>
        <TabBar
          items={
            [
              {value: "groups", label: "Groups", icon: listOutline, onClick: () => this.setState({activeView: 'groups'})},
              {value: "messages", label: "Messages", icon: chatboxOutline, onClick: () => this.setState({activeView: 'messages'})},
              {value: "board", label: "Board", icon: ribbonOutline, onClick: () => this.setState({activeView: 'board'})},
              {value: "members", label: "Members", icon: peopleOutline, onClick: () => this.setState({activeView: 'members'})},
              {value: "boardroles", label: "Board roles", icon: pricetagsOutline, onClick: () => this.setState({activeView: 'boardroles'})},
              {value: "locations", label: "Locations", icon: mapOutline, onClick: () => this.setState({activeView: 'locations'})},
            ]
          }
          activeValue={this.state.activeView}
        />
      </IonPage>
    )
  }

  private renderContent(isLoading: boolean, organisation: Organisation) {
    return (
      <DataContainer
        isLoading={isLoading}
        contentRender={() => {
          if (this.state.activeView === 'messages') {
            return this.renderMessagesContent();
          } else if (this.state.activeView === 'board') {
            return this.renderBoardContent();
          } else if (this.state.activeView === 'members') {
            return this.renderMembersContent();
          } else if (this.state.activeView === 'boardroles') {
            return this.renderBoardRolesContent(organisation);
          }  else if (this.state.activeView === 'locations') {
            return this.renderLocationsContent(organisation);
          } else {
            return this.renderGroupsContent(organisation);
          }
        }}
      />
    )
  }

  private renderGroupsContent(organisation: Organisation) {
    const groups = this.props.selectedOrganisation.groups;
    groups.setNameFilter(this.state.groupSearchNeedle);
    return (
      <div className="content-container">
        <ListingByName
          items={
            groups.data.map((group, index) => {
              return {
                name: group.name,
                onView: () => {
                  logger.info("navigate to organisation group detail", group.id, "for organisation", organisation.id);
                  this.props.history.push(`/organisations/${organisation.id}/groups/${group.id}`);
                }
              }
            })
          }
          onSearchNeedleChanged={newValue => this.setState({groupSearchNeedle: newValue})}
        />
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton>
            <IonIcon icon={addOutline}
                     onClick={() => this.props.history.push({
                       pathname: `/organisations/${organisation.id}/groups/new`,
                       state: {organisationName: organisation.name}
                     })}
            />
          </IonFabButton>
        </IonFab>
      </div>
    )
  }

  private renderBoardRolesContent(organisation: Organisation) {
    const roles = organisation.availableMemberRoles;
    roles.setFilter(this.state.roleSearchNeedle);
    return (
      <div className="content-container">
        <ListingByName
          items={
            roles.data.map((role, index) => {
              return {
                name: role.name
              }
            })
          }
          onSearchNeedleChanged={newValue => this.setState({roleSearchNeedle: newValue})}
        />
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton>
            <IonIcon icon={addOutline}
                     onClick={() => this.props.history.push({
                       pathname: `/organisations/${organisation.id}/boardroles/new`,
                       state: {organisationName: organisation.name}
                     })}
            />
          </IonFabButton>
        </IonFab>
      </div>
    )
  }

  private renderLocationsContent(organisation: Organisation) {
    const locations = organisation.locations;
    locations.setNameFilter(this.state.locationSearchNeedle);
    return (
      <div className="content-container">
        <ListingByName
          items={
            locations.data.map((location, index) => {
              return {
                name: location
              }
            })
          }
          onSearchNeedleChanged={newValue => this.setState({locationSearchNeedle: newValue})}
        />
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton>
            <IonIcon icon={addOutline}
                     onClick={() => this.props.history.push({
                       pathname: `/organisations/${organisation.id}/locations/new`,
                       state: {organisationName: organisation.name}
                     })}
            />
          </IonFabButton>
        </IonFab>
      </div>
    )
  }

  private renderMessagesContent() {
    return (
      <div className="content-container">
        <IonList>
          <IonItem>
            <IonAvatar slot="start">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                Net terug uit vakantie: splitsen we de standups of niet?.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                Dat zou ik ook wel willen weten.<br/>De laatste week was het alleszins wel het geval.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:44 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonCard slot="end">
              <IonCardContent>
                Onnozelaars, natuurlijk is het gesplitst.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
            <IonAvatar slot="end">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                ok, merci.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                thx.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:44 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonCard slot="end">
              <IonCardContent>
                Graag gedaan
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
            <IonAvatar slot="end">
              <img src={TEST_AVATAR}/>
            </IonAvatar>
          </IonItem>
          <IonItemDivider/>
          <IonItem>
            {/*<IonTextarea rows={6} cols={20} placeholder="Enter any notes here..." value={text} onIonChange={e => setText(e.detail.value!)}></IonTextarea>*/}
            <IonTextarea rows={3} cols={20} placeholder="Enter response...">&nbsp;</IonTextarea>
            <IonRow>
              <IonCol>
                <IonButton type="submit" expand="block">Submit</IonButton>
              </IonCol>
            </IonRow>
          </IonItem>
        </IonList>
      </div>
    )
  }

  private renderBoardContent() {
    const organisation = this.props.selectedOrganisation;
    const board = organisation.board;
    board.setNameFilter(this.state.boardSearchNeedle);
    return (
      <div className="content-container">
        <ListingByAvatarTitleAndSubtitle
          items={
            board.data.map((member, index) => {
              return {
                title: member.name,
                avatar: member.avatar,
                subtitle: member.boardRoles.data.map(r => r.name).join(", "),
                onEdit: () => {
                  this.props.history.push(`/organisations/${organisation.id}/board/${member.id}/edit`);
                }
              }
            })
          }
          onSearchNeedleChanged={newValue => this.setState({boardSearchNeedle: newValue})}
        />
      </div>
    )
  }

  private renderMembersContent() {
    const organisation = this.props.selectedOrganisation;
    const members = organisation.members;
    members.setNameFilter(this.state.memberSearchNeedle);
    return (
      <div className="content-container">
        <ListingByAvatarTitleAndSubtitle
          items={
            members.data.map((member, index) => {
              return {
                title: member.name,
                avatar: member.avatar,
                subtitle: member.groups.map(g => organisation.groups.getById(g).name).join(','),
                onEdit: () => {
                  this.props.history.push(`/organisations/${organisation.id}/members/${member.id}/edit`);
                }
              }
            })
          }
          onSearchNeedleChanged={newValue => this.setState({memberSearchNeedle: newValue})}
        />
      </div>
    )
  }
}

export default connect<OrganisationDetailProps>({
  mapStateToProps: (state) => ({
    selectedOrganisation: state.organisationsState.selectedOrganisation,
    isOrganisationLoading: state.organisationsState.isOrganisationLoading,
  }),
  mapDispatchToProps: {
    loadUserOrganisation,
  },
  component: withIonLifeCycle(OrganisationDetail)
})
