export default class Logger {
  static info(message: string, ...optionalParams: any[]) {
    if(optionalParams && optionalParams.length > 0){
      console.info(message, optionalParams)
    } else {
      console.info(message)
    }
  }
  static debug(message: string, ...optionalParams: any[]) {
    if(optionalParams && optionalParams.length > 0){
      console.debug(message, optionalParams)
    } else {
      console.debug(message)
    }
  }
  static error(message: string, ...optionalParams: any[]) {
    if(optionalParams && optionalParams.length > 0){
      console.error(message, optionalParams)
    } else {
      console.error(message)
    }
  }
  static warn(message: string, ...optionalParams: any[]) {
    if(optionalParams && optionalParams.length > 0){
      console.warn(message, optionalParams)
    } else {
      console.warn(message)
    }
  }
}
