export default class ViaeDateUtils {
  static isSameDay(date1: Date, date2: Date): boolean {
    return this.daysDiff(date1, date2) === 0;
  }

  static daysDiff(date1: Date, date2: Date): number {
    const secondsDiff = ViaeDateUtils.secondsDiff(date1, date2);
    if (secondsDiff >= 86400) {
      return Math.floor(secondsDiff / 86400)
    } else {
      return 0;
    }
  }

  static hoursDiff(date1: Date, date2: Date): number {
    return (date2.getTime() - date1.getTime()) / 3600000;
  }

  static secondsDiff(date1: Date, date2: Date): number {
    return (date2.getTime() - date1.getTime()) / 1000;
  }

  static millisecondsDiff(date1: Date, date2: Date): number {
    return (date2.getTime() - date1.getTime());
  }

  static milliSecondsDiff(date1: Date, date2: Date): number {
    return (date2.getTime() - date1.getTime());
  }


  static isInThePast(date: Date): boolean {
    return date < ViaeDateUtils.getNowAsDate();
  }

  static addDays(date: Date, days: number): Date { //TODO test
    const dat = new Date(date.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
  }

  static getNowAsDate(): Date { //TODO test
    const date = new Date();
    date.setDate(date.getDate());
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static getNowAsTimeStamp(): Date { //TODO test
    return new Date();
  }

  static addDaysToNowAsDate(days: number) { //TODO test
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + days);
    return date;
  }

  static addYearsToNowAsDate(years: number) { //TODO test
    const date = new Date();
    date.setFullYear(date.getFullYear() + years, date.getMonth(), date.getDate())
    return date;
  }

  static addMonthsToNowAsDate(months: number): Date { //TODO test
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setMonth(date.getMonth() + months);
    return date;
  }

  static addSecondsToNowAsTimeStamp(seconds: number) { //TODO test
    const date = new Date();
    date.setHours(0, 0, date.getSeconds() + seconds, 0);
    return date;
  }

  static toString(dateValue: Date): string {
    const d = dateValue.getDate();
    const m = dateValue.getMonth() + 1;
    const y = dateValue.getFullYear();
    return (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
  }

  static toBackendFormattedString(dateValue: Date): string {
    const d = dateValue.getDate();
    const m = dateValue.getMonth() + 1;
    const y = dateValue.getFullYear();
    return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
  }

  static toBackendFormattedDateTimeString(dateValue: Date): string {
    const d = dateValue.getDate();
    const m = dateValue.getMonth() + 1;
    const y = dateValue.getFullYear();
    const h = dateValue.getHours();
    const min = dateValue.getMinutes();
    return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + 'T' + h + ':' + min;
  }

  static nowAsBackendFormattedString(): string {
    return ViaeDateUtils.toBackendFormattedString(ViaeDateUtils.getNowAsDate())
  }

  static fromStringToDate(stringValue: string): Date | undefined {
    const matchesArray = stringValue.match(/([0-9]{4})-([0-9]{2})-([0-9]{2}).*/i);
    if (matchesArray) {
      const date = new Date(Number(matchesArray[1]), Number(matchesArray[2]) - 1, Number(matchesArray[3]));
      date.setHours(0, 0, 0);
      return date;
    } else {
      return undefined;
    }
  }

  static fromStringToDateTime(stringValue: string): Date | undefined {
    const matchesArray = stringValue.match(/([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2}).*/i);
    if (matchesArray) {
      return new Date(
        Number(matchesArray[1]),
        Number(matchesArray[2]) - 1,
        Number(matchesArray[3]),
        Number(matchesArray[4]),
        Number(matchesArray[5]),
        Number(matchesArray[6])
      );
    } else {
      return undefined;
    }
  }
}
