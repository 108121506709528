import React, {Component} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, withIonLifeCycle} from '@ionic/react';

import {RouteComponentProps} from "react-router";

import "./NotificationListing.scss"
import {loadUserNotifications, markNewNotificationsRead} from "../../data/notifications/notifications.actions";
import {connect} from "../../data/connect";
import DataContainer from "../components/DataContainer";
import logger from "../../util/logger";
import Logger from "../../util/logger";
import {Notifications} from "../../data/notifications/domain/Notification";
import ListingByName from "../components/ListingByName";

interface NotificationListingProps extends RouteComponentProps {
  loadUserNotifications: typeof loadUserNotifications;
  markNewNotificationsRead: typeof markNewNotificationsRead;
  notifications: Notifications;
  areNotificationsLoading: boolean;
}

interface NotificationListingState {
  notificationSearchNeedle: string;
  loadTriggered: boolean;
}

class NotificationListing extends Component<NotificationListingProps, NotificationListingState> {

  constructor(props: NotificationListingProps) {
    super(props);
    this.state = {
      notificationSearchNeedle: "",
      loadTriggered: false
    }
  }

  ionViewWillEnter() {
    if (this.state.loadTriggered) {
      Logger.info("user notifications already loaded");
    } else {
      Logger.info("load users notification");
      this.props.loadUserNotifications();
      this.setState({loadTriggered: true})
    }
  }

  ionViewWillLeave() {
    Logger.info("reset user notifications load trigger");
    this.setState({loadTriggered: false})
  }

  componentDidUpdate(prevProps: Readonly<NotificationListingProps>, prevState: Readonly<NotificationListingState>, snapshot?: any) {
    if(this.state.loadTriggered && this.props.notifications.unReadNotificationCount > 0){
      this.props.markNewNotificationsRead()
    }
  }

  render() {
    return (
      <IonPage id="notification-listing-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton/>
            </IonButtons>
            <IonTitle>{`Notifications overview`}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="content-container">
            <DataContainer
              isLoading={this.props.areNotificationsLoading}
              contentRender={() => {
                return (
                  <ListingByName
                    items={
                      this.props.notifications.data.map((notification, index) => {
                        return {
                          name: notification.name,
                          onView: () => {
                            logger.info("navigate to notification detail", notification.id)
                            this.props.history.push(`/calendar/events/37#messages`)
                          }
                        }
                      })
                    }
                    onSearchNeedleChanged={this.notificationSearchNeedleChanged}
                  />
                )
              }}
            />
          </div>
        </IonContent>

      </IonPage>
    );
  }

  private notificationSearchNeedleChanged(newValue: string) {
    this.setState({notificationSearchNeedle: newValue});
  }

}

export default connect<NotificationListingProps>({
  mapStateToProps: (state) => ({
    notifications: state.notificationsState.notifications,
    areNotificationsLoading: state.notificationsState.areNotificationsLoading,
  }),
  mapDispatchToProps: {
    loadUserNotifications,
    markNewNotificationsRead
  },
  component: withIonLifeCycle(NotificationListing)
})
