import {ActionType} from '../../util/types';
import {getNotifications, markNewNotificationsRead as doMarkNewNotificationsRead} from "./notifications.api";
import {NotificationsState} from "./notifications.state";
import logger from "../../util/logger";
import {NotificationsData} from "./staticdata/notificationdata";
import {Notification} from "./domain/Notification";

export const loadUserNotifications = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setNotificationsLoading(true));
  const data = await getNotifications();
  logger.debug("got notifications", data);
  dispatch(setNotificationsData(data));
  dispatch(setNotificationsLoading(false));

  mockNewNotification(dispatch, data);
}

export const markNewNotificationsRead = () => async (dispatch: React.Dispatch<any>) => {
  const data = await doMarkNewNotificationsRead();
  logger.debug("marked new notifications as read", data);
  dispatch(setNotificationsData(data));
}

function mockNewNotification(dispatch: any, data: any){
  dispatch(setNotificationsData({...data, notifications: NotificationsData.DB}));
  setTimeout(() => {//TODO remove when backend got attached
    NotificationsData.DB.addNotification(new Notification((NotificationsData.DB.data.length + 1).toString(), "new notification"));
    dispatch(setNotificationsData({...data, notifications: NotificationsData.DB}));
    mockNewNotification(dispatch, data);
  }, 1000)
}

export const setNotificationsLoading = (isLoading: boolean) => ({
  type: 'set-notifications-loading',
  isLoading
} as const);

export const setNotificationsData = (data: Partial<NotificationsState>) => ({
  type: 'set-notifications-data',
  data
} as const);

export type NotificationsActions =
  | ActionType<typeof setNotificationsLoading>
  | ActionType<typeof setNotificationsData>
