import {ActionType} from '../../util/types';
import {CalendarState} from "./calendar.state";
import logger from "../../util/logger";
import {getCalendar, getEvent, mockSaveEvent} from "./calendar.api";
import Reference from "../domain/Reference";
import {CalendarEventId} from "./domain/CalendarEvent";

export interface SaveEventRequest {
  eventId: string | undefined;
  name: string;
  start: string;
  end?: string;
  organisation: Reference;
  organisationGroups?: Reference[];
  location?: string;
  comments?: string
  isAllDayEvent: boolean;
  isRecurrentEvent: boolean;
  recurringDayFrequency: number | undefined;
  recurCount: number | undefined;
}

export const loadCalendar = (onNotFound: () => void) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setCalendarLoading(true));
  const onFound = (data: CalendarState) => {
    logger.debug("got organisation", data);
    dispatch(setCalendarData(data));
    dispatch(setCalendarLoading(false));
  }
  const internalOnNotFound = () => {
    dispatch(setCalendarLoading(false));
    onNotFound();
  }
  await getCalendar(onFound, internalOnNotFound);
}

export const loadEvent = (eventId: CalendarEventId, onNotFound: () => void) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setEventLoading(true));
  const onFound = (data: CalendarState) => {
    logger.debug("got event", data);
    dispatch(setCalendarData(data));
    dispatch(setEventLoading(false));
  }
  const internalOnNotFound = () => {
    dispatch(setEventLoading(false));
    onNotFound();
  }
  await getEvent(eventId, onFound, internalOnNotFound);
}

export const saveEvent = (request: SaveEventRequest, onSuccess: () => void, onError: (reason: any) => void) => async (dispatch: React.Dispatch<any>) => {
  await mockSaveEvent(request).then(onSuccess).catch(onError);
  if(request.eventId){
    logger.debug("event edited", request);
  } else {
    logger.debug("event created", request);
  }
}

export const setCalendarLoading = (isLoading: boolean) => ({
  type: 'set-calendar-loading',
  isLoading
} as const);

export const setEventLoading = (isLoading: boolean) => ({
  type: 'set-event-loading',
  isLoading
} as const);

export const setCalendarData = (data: Partial<CalendarState>) => ({
  type: 'set-calendar-data',
  data
} as const);

export type CalendarActions =
  | ActionType<typeof setCalendarLoading>
  | ActionType<typeof setEventLoading>
  | ActionType<typeof setCalendarData>
