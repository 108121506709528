import {OrganisationMemberRole, OrganisationMemberRoles} from "./OrganisationMemberRole";
import {OrganisationGroupId} from "./OrganisationGroup";

export type OrganisationMemberId = string

interface OrganisationMemberFilter {
  name: string;
  isBoardMember?: boolean;
  id: string;
}

const DEFAULT_FILTER = {name: "", isBoardMember: undefined, id: ""};

export class OrganisationMembers {
  constructor(private _members: OrganisationMember[] = [], private filter: OrganisationMemberFilter = DEFAULT_FILTER) {
  }

  setNameFilter(filter: string): OrganisationMembers {
    this.filter = {...this.filter, name: filter};
    return this;
  }

  setIsBoardFilter(isBoardMember?: boolean): OrganisationMembers {
    this.filter = {...this.filter, isBoardMember: isBoardMember};
    return this;
  }

  setIdFilter(id: string): OrganisationMembers {
    this.filter = {...this.filter, id: id};
    return this;
  }

  clearFilter() {
    this.filter = DEFAULT_FILTER;
    return this;
  }

  get data(): OrganisationMember[] {
    return this._members.filter((om: OrganisationMember) => {
      const nameMatch = om.name.toLowerCase().indexOf(this.filter.name) !== -1
      const idMatch = om.id.toLowerCase().indexOf(this.filter.id) !== -1
      const boardMemberCheckMatch = this.filter.isBoardMember === undefined || om.isBoardMember === this.filter.isBoardMember
      return nameMatch && boardMemberCheckMatch && idMatch
    }).sort((a, b) => a.name.localeCompare(b.name));
  }

  get single(): OrganisationMember {
    if (this.data.length === 1) {
      return this.data[0];
    } else {
      throw Error("Not one single result for organisation member list: " + this.data.map(g => g.name).join(','))
    }
  }

  getById(id: string): OrganisationMember {
    return this._members.filter(m => m.id === id)[0]
  }
}

export class OrganisationMember {
  private _roles: OrganisationMemberRoles;

  constructor(
    private _id: OrganisationMemberId,
    private _name: string,
    private _isBoardMember: boolean,
    private _avatar: string,
    _boardRoles: OrganisationMemberRole[],
    private _groups: OrganisationGroupId[] = []
  ) {
    this._roles = new OrganisationMemberRoles(_boardRoles);
  }

  get id(): OrganisationMemberId {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get boardRoles(): OrganisationMemberRoles {
    return this._roles;
  }

  get avatar(): string {
    return this._avatar;
  }

  get isBoardMember(): boolean {
    return this._isBoardMember;
  }

  get groups(): OrganisationGroupId[] {
    return this._groups;
  }

  edit(isBoardMember: boolean, roles: OrganisationMemberRoles) { //TODO => remove this method when BE is there
    this._isBoardMember = isBoardMember;
    this._roles = roles;
  }

  belongsToGroup(groupId: OrganisationGroupId): boolean {
    return this._groups.indexOf(groupId) !== -1;
  }

  addGroup(organisationGroupId: OrganisationGroupId) {//TODO => remove this method when BE is there
    if(this._groups.indexOf(organisationGroupId) === -1){
      this._groups.push(organisationGroupId)
    }
  }

  removeGroup(organisationGroupId: OrganisationGroupId) {//TODO => remove this method when BE is there
    this._groups = this._groups.filter(g => g !== organisationGroupId)
  }
}
