import logger from "./logger";

export class FormValidator {
  private elements: ValidatedElement[] = [];

  registerValidatedElement(element: ValidatedElement) {
    this.elements.push(element);
  }

  isFormValid(): boolean {
    const isValid = this.elements.map(e => e.validate()).filter(isValid => !isValid).length === 0;
    if(!isValid){
      logger.info("validation fails for", this.elements.filter(e => !e.validate()).map(e => e.constructor.name))
    }
    return isValid;
  }

  clearErrors(){
    this.elements.forEach(e => e.clearErrors());
  }
}

export interface ValidatedElement {
  validate(): boolean;
  clearErrors(): void;
}
