import React, {Component} from "react";
import {IonIcon, IonLabel, IonTabBar, IonTabButton} from "@ionic/react";

export interface TabBarItem {
  label: string;
  value: string;
  icon: string;
  onClick: () => void;
}

interface TabBarProps {
  items: TabBarItem[];
  activeValue: string;
}

export default class TabBar extends Component<TabBarProps> {

  render() {
    return (
      <IonTabBar slot="bottom">
        {this.props.items.map((item, index) => {
          return (
            <IonTabButton key={index} tab={item.value} onClick={item.onClick} selected={this.props.activeValue === item.value}>
              <IonIcon icon={item.icon}/>
              <IonLabel>{item.label}</IonLabel>
            </IonTabButton>
          )
        })}
      </IonTabBar>
    )
  }
}
