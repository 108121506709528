import {ListingByAvatarTitleAndSubtitleItem} from "../../../pages/components/ListingByAvatarTitleAndSubtitle";
import {OrganisationGroupMemberRole, OrganisationGroupMemberRoles} from "./OrganisationGroupMemberRole";

export type OrganisationGroupMemberId = string

interface OrganisationGroupMemberFilter {
  name: string;
  isResponsible?: boolean;
  id: string;
}

const DEFAULT_FILTER = {name: "", isResponsible: undefined, id: ""};

export class OrganisationGroupMembers {
  constructor(private _groupMembers: OrganisationGroupMember[] = [], private filter: OrganisationGroupMemberFilter = DEFAULT_FILTER) {
  }

  setNameFilter(filter: string): OrganisationGroupMembers {
    this.filter = {...this.filter, name: filter};
    return this;
  }

  setIsResponsibleFilter(isResponsible?: boolean): OrganisationGroupMembers {
    this.filter = {...this.filter, isResponsible: isResponsible};
    return this;
  }

  setIdFilter(id: string): OrganisationGroupMembers {
    this.filter = {...this.filter, id: id};
    return this;
  }

  clearFilter() {
    this.filter = DEFAULT_FILTER;
    return this;
  }

  get data(): OrganisationGroupMember[] {
    return this._groupMembers.filter((om: OrganisationGroupMember) => {
      const nameMatch = om.name.toLowerCase().indexOf(this.filter.name) !== -1
      const idMatch = om.id.toLowerCase().indexOf(this.filter.id) !== -1
      const isResponsibleCheckMatch = this.filter.isResponsible === undefined || om.isResponsible === this.filter.isResponsible
      return nameMatch && isResponsibleCheckMatch && idMatch
    }).sort((a, b) => a.name.localeCompare(b.name));
  }

  remove(memberId: OrganisationGroupMemberId) { //TODO remove this method when BE gets created
    this._groupMembers = this._groupMembers.filter(m => m.id !== memberId);
  }

  add(member: OrganisationGroupMember) { //TODO remove this method when BE gets created
    this.remove(member.id);
    this._groupMembers.push(member);
  }

  getById(id: string): OrganisationGroupMember {
    return this._groupMembers.filter(m => m.id === id)[0]
  }
}

export class OrganisationGroupMember {
  private _roles: OrganisationGroupMemberRoles;

  constructor(
    private _id: OrganisationGroupMemberId,
    private _name: string,
    private _isResponsible: boolean,
    _roles: OrganisationGroupMemberRole[],
    private _avatar: string
  ) {
    this._roles = new OrganisationGroupMemberRoles(_roles);
  }

  get id(): OrganisationGroupMemberId {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get roles(): OrganisationGroupMemberRoles {
    return this._roles;
  }

  get avatar(): string {
    return this._avatar;
  }

  get isResponsible(): boolean {
    return this._isResponsible;
  }

  setResponsible(isResponsible: boolean) { //TODO remove this method when BE gets created
    this._isResponsible = isResponsible;
  }

  edit(isResponsible: boolean, roles: OrganisationGroupMemberRoles) { //TODO => remove this method when BE is there
    this._isResponsible = isResponsible;
    this._roles = roles;
  }

  public toListingByAvatarTitleAndSubtitleItem(onEdit?: () => void): ListingByAvatarTitleAndSubtitleItem {
    return {
      title: this._name,
      avatar: this._avatar,
      subtitle: this._roles.data.map(role => role.name).join(", "),
      onEdit: onEdit
    };
  }
}
