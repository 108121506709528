import React, {Component} from "react";
import {IonAvatar, IonIcon, IonItem, IonLabel, IonList, IonSearchbar, IonText} from "@ionic/react";
import {SearchbarChangeEventDetail} from "@ionic/core";
import {addOutline, eyeOutline, pencilOutline, removeOutline} from "ionicons/icons";
import "./Listing.scss"

export interface ListingByAvatarTitleAndSubtitleItemCustomAction {
  render: (index: any) => any;
}

export interface ListingByAvatarTitleAndSubtitleItem {
  title: string;
  avatar: string;
  subtitle?: string;
  onView?: () => void;
  onEdit?: () => void;
  onAdd?: () => void;
  onRemove?: () => void;
  customActions?: ListingByAvatarTitleAndSubtitleItemCustomAction[]
}

interface ListingByAvatarTitleAndSubtitleProps {
  items: ListingByAvatarTitleAndSubtitleItem[];
  onSearchNeedleChanged?: (newValue: string) => void;
}

interface ListingByAvatarTitleAndSubtitleState {
  searchNeedle: string;
}

export default class ListingByAvatarTitleAndSubtitle extends Component<ListingByAvatarTitleAndSubtitleProps, ListingByAvatarTitleAndSubtitleState> {
  constructor(props: ListingByAvatarTitleAndSubtitleProps) {
    super(props);
    this.state = {
      searchNeedle: ""
    }
  }

  render() {
    return (
      <IonList className={"listing-component"}>
        <IonSearchbar
          value={this.state.searchNeedle}
          onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>) => {
            if (this.props.onSearchNeedleChanged) {
              let newValue = e.detail.value!;
              this.setState({searchNeedle: newValue});
              this.props.onSearchNeedleChanged(newValue);
            }
          }}
          onIonClear={() => {
            if (this.props.onSearchNeedleChanged) {
              this.setState({searchNeedle: ""});
              this.props.onSearchNeedleChanged("");
            }
          }}
        >
          &nbsp;
        </IonSearchbar>
        {this.props.items.map((item, index) => this.renderItem(item, index))}
      </IonList>
    )
  }

  private renderItem(item: ListingByAvatarTitleAndSubtitleItem, index: number) {
    const customActions = (item.customActions || []).map((ca, index) => ca.render(index))
    const existingActions = [item.onView, item.onEdit, item.onAdd, item.onRemove, ...customActions].filter(a => a !== undefined)
    let defaultAction;
    if(existingActions.length === 1){
      defaultAction = existingActions[0];
    } else {
      defaultAction = () => {}
    }

    return (
      <IonItem key={index} className={"hoverable list-item-with-icon"} onClick={defaultAction}>
        <IonAvatar slot="start">
          <img src={item.avatar} alt={item.title}/>
        </IonAvatar>
        <IonLabel className={"label"}>
          <IonText className={"text"}>{item.title}</IonText><br/>
          <IonText className={"text"}><small className={"subtitle"}>{item.subtitle}</small></IonText>
        </IonLabel>
        {(item.customActions || []).map((ca, index) => ca.render(index))}
        {item.onView && <IonIcon onClick={item.onView} className={"icon"} icon={eyeOutline} slot={"end"}>&nbsp;</IonIcon>}
        {item.onEdit && <IonIcon onClick={item.onEdit} className={"icon"} icon={pencilOutline} slot={"end"}>&nbsp;</IonIcon>}
        {item.onAdd && <IonIcon onClick={item.onAdd} className={"icon"} icon={addOutline} slot={"end"}>&nbsp;</IonIcon>}
        {item.onRemove && <IonIcon onClick={item.onRemove} className={"icon"} icon={removeOutline} slot={"end"}>&nbsp;</IonIcon>}
      </IonItem>
    );
  }
}
