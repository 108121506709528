export type OrganisationMemberRoleId = string

export class OrganisationMemberRoles {
  constructor(private _roles: OrganisationMemberRole[] = [], private filter: string = "") {
  }

  setFilter(filter: string): OrganisationMemberRoles {
    this.filter = filter.toLowerCase();
    return this;
  }

  get data(): OrganisationMemberRole[] {
    return this._roles.filter((ag: OrganisationMemberRole) => ag.name.toLowerCase().indexOf(this.filter) !== -1).sort((a, b) => a.name.localeCompare(b.name));
  }

  containsId(id: string): boolean {
    return this._roles.filter(r => r.id === id).length > 0
  }

  getById(id: string): OrganisationMemberRole {
    return this._roles.filter(r => r.id === id)[0]
  }

  add(role: OrganisationMemberRole) { //TODO remove this method when BE gets created
    this._roles.push(role);
  }
}

export class OrganisationMemberRole {

  constructor(
    private _id: OrganisationMemberRoleId,
    private _name: string,
  ) {
  }

  get id(): OrganisationMemberRoleId {
    return this._id;
  }

  get name(): string {
    return this._name;
  }
}
