import {OrganisationGroup} from "../domain/OrganisationGroup";
import {OrganisationGroupMember} from "../domain/OrganisationGroupMember";
import {OrganisationGroupMemberRole, OrganisationGroupMemberRoleId} from "../domain/OrganisationGroupMemberRole";
import {TEST_AVATAR} from "../../avatars";
import {Organisation, OrganisationId} from "../domain/Organisation";
import {BASF as BASFOrganisation, Harmonie as HarmonieOrganisation, Toneel as ToneelOrganisation} from "./organisationdata";

let toneelGroupCounter = 1;
let harmonieGroupCounter = 1;

const BASF = {
  Phoenix: new OrganisationGroup(
    "1",
    "Phoenix", [
      member(BASFOrganisation.ORGANISATION, "Maarten Vandeperre", [role("1", "Scrum master"), role("2", "Developer")]),
      member(BASFOrganisation.ORGANISATION, "Dominic Maes", [role("3", "Tester")]),
      member(BASFOrganisation.ORGANISATION, "Thomas Roelens", [role("2", "Developer")])
    ])
}

const Toneel = {
  Acteurs: new OrganisationGroup(
    (toneelGroupCounter++).toString(),
    "Acteurs", [
      member(ToneelOrganisation.ORGANISATION, "Ceulemans wesley", []),
      member(ToneelOrganisation.ORGANISATION, "De Vadder Steven", []),
      member(ToneelOrganisation.ORGANISATION, "Denys Sam", []),
      member(ToneelOrganisation.ORGANISATION, "Embregts Katrien", []),
      responsible(ToneelOrganisation.ORGANISATION, "Goossens Bart", []),
      member(ToneelOrganisation.ORGANISATION, "Goossens Inne", []),
      member(ToneelOrganisation.ORGANISATION, "Goossens Joke", []),
      member(ToneelOrganisation.ORGANISATION, "Karnas Indy", []),
      member(ToneelOrganisation.ORGANISATION, "Kint Luc", []),
      responsible(ToneelOrganisation.ORGANISATION, "Maes Dominic", []),
      member(ToneelOrganisation.ORGANISATION, "Maes Eline", []),
      member(ToneelOrganisation.ORGANISATION, "Martine", []),
      member(ToneelOrganisation.ORGANISATION, "Nelissen Ellyn", []),
      member(ToneelOrganisation.ORGANISATION, "Tavernier Luc", []),
      responsible(ToneelOrganisation.ORGANISATION, "Torfs Patricia", []),
      member(ToneelOrganisation.ORGANISATION, "Van Kerckhoven Joke", []),
      member(ToneelOrganisation.ORGANISATION, "Verhaert Nathalie", [])
    ]),
  Techniek: new OrganisationGroup(
    (toneelGroupCounter++).toString(),
    "Techniek", [
      member(ToneelOrganisation.ORGANISATION, "Janssens Ivan", []),
      member(ToneelOrganisation.ORGANISATION, "Kint Joris", []),
      member(ToneelOrganisation.ORGANISATION, "Kint Luc", []),
      responsible(ToneelOrganisation.ORGANISATION, "Maes Dominic", []),
    ])
}

const Harmonie = {
  Muzikanten: new OrganisationGroup(
    (harmonieGroupCounter++).toString(),
    "Muzikanten", [
      member(HarmonieOrganisation.ORGANISATION, "Achtergaele Pieter", []),
      member(HarmonieOrganisation.ORGANISATION, "Bekaert Tobias", []),
      member(HarmonieOrganisation.ORGANISATION, "Bolssens Bernadette", []),
      member(HarmonieOrganisation.ORGANISATION, "Brys Ellis", []),
      member(HarmonieOrganisation.ORGANISATION, "Cerpentier Christine", []),
      member(HarmonieOrganisation.ORGANISATION, "Coen Eddy", []),
      member(HarmonieOrganisation.ORGANISATION, "De Blieck Ivan", []),
      member(HarmonieOrganisation.ORGANISATION, "De Blieck Robert", []),
      member(HarmonieOrganisation.ORGANISATION, "De Bock Paul", []),
      member(HarmonieOrganisation.ORGANISATION, "De Caluwe Adrienne", []),
      member(HarmonieOrganisation.ORGANISATION, "De Cock Gino", []),
      member(HarmonieOrganisation.ORGANISATION, "De Haeck Theo", []),
      member(HarmonieOrganisation.ORGANISATION, "De Nys Philip", []),
      member(HarmonieOrganisation.ORGANISATION, "De Prijcker Koen", []),
      member(HarmonieOrganisation.ORGANISATION, "De Smet Jonathan", []),
      member(HarmonieOrganisation.ORGANISATION, "De Smet Leo", []),
      member(HarmonieOrganisation.ORGANISATION, "De Wilde Sophie", []),
      member(HarmonieOrganisation.ORGANISATION, "De Witte Frans", []),
      member(HarmonieOrganisation.ORGANISATION, "Goossens Antoine", []),
      member(HarmonieOrganisation.ORGANISATION, "Lippens Marian", []),
      member(HarmonieOrganisation.ORGANISATION, "Martens Etienne", []),
      member(HarmonieOrganisation.ORGANISATION, "Noët Annick", []),
      member(HarmonieOrganisation.ORGANISATION, "Pieters Patrick", []),
      member(HarmonieOrganisation.ORGANISATION, "Plehiers Tom", []),
      member(HarmonieOrganisation.ORGANISATION, "Roggeman Marc", []),
      member(HarmonieOrganisation.ORGANISATION, "Schelfhout Jeroen", []),
      member(HarmonieOrganisation.ORGANISATION, "Stevens Dirk", []),
      member(HarmonieOrganisation.ORGANISATION, "Van Bogaert Conny", []),
      member(HarmonieOrganisation.ORGANISATION, "Van Bogaert Willy", []),
      member(HarmonieOrganisation.ORGANISATION, "Van Daele Hans", []),
      member(HarmonieOrganisation.ORGANISATION, "Van Daele Jasmien", []),
      member(HarmonieOrganisation.ORGANISATION, "Van Damme Ingrid", []),
      member(HarmonieOrganisation.ORGANISATION, "Van Damme Paul", []),
      member(HarmonieOrganisation.ORGANISATION, "Van der Weken Freddy", []),
      member(HarmonieOrganisation.ORGANISATION, "Van Eetvelde Tinneke", []),
      member(HarmonieOrganisation.ORGANISATION, "Van Poeck Mirthe", []),
      member(HarmonieOrganisation.ORGANISATION, "Van Puyvelde Manuella", []),
      member(HarmonieOrganisation.ORGANISATION, "Vande Sande Youri", []),
      member(HarmonieOrganisation.ORGANISATION, "Vansteenkiste Lore", []),
      member(HarmonieOrganisation.ORGANISATION, "Verbraeken Luc", []),
      member(HarmonieOrganisation.ORGANISATION, "Wauters André", []),
      member(HarmonieOrganisation.ORGANISATION, "Weermann Margriet", []),
      member(HarmonieOrganisation.ORGANISATION, "Wuytack Shirley", [])
    ],
    [
      new OrganisationGroupMemberRole("1", "Hobo's"),
      new OrganisationGroupMemberRole("2", "Trompet")
    ]),
  TrommelKorps: new OrganisationGroup(
    (harmonieGroupCounter++).toString(),
    "TrommelKorps", [
      member(HarmonieOrganisation.ORGANISATION, "Maes Eline", []),
      member(HarmonieOrganisation.ORGANISATION, "Torfs Patricia", []),
      member(HarmonieOrganisation.ORGANISATION, "Vansteenkiste Lore", []),
    ])
}

export const organisationGroupDatabase: Map<OrganisationId, OrganisationGroup[]> = new Map();
organisationGroupDatabase.set("3", [BASF.Phoenix]);
organisationGroupDatabase.set("5", [Toneel.Acteurs, Toneel.Techniek]);
organisationGroupDatabase.set("6", [Harmonie.Muzikanten, Harmonie.TrommelKorps]);


function member(organisation: Organisation, name: string, roles: OrganisationGroupMemberRole[]) {
  const members = organisation.members.data.filter(m => m.name === name);
  if (!members || members.length === 0) {
    throw Error(`member ${name} not found in organisation ${organisation.name}`)
  }
  return new OrganisationGroupMember(members[0].id, name, false, roles, TEST_AVATAR)
}

function responsible(organisation: Organisation, name: string, roles: OrganisationGroupMemberRole[]) {
  const members = organisation.members.data.filter(m => m.name === name);
  if (!members || members.length === 0) {
    throw Error(`member ${name} not found in organisation ${organisation.name}`)
  }
  return new OrganisationGroupMember(members[0].id, name, true, roles, TEST_AVATAR)
}

function role(id: string, name: string) {
  return new OrganisationGroupMemberRole(id, name)
}
