import React, {Component} from 'react';
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';

import {RouteComponentProps} from "react-router";
import {addOutline, chatboxOutline, eyeOutline, pencilOutline, peopleOutline, pricetagsOutline, ribbonOutline} from "ionicons/icons";

import "./OrganisationGroupDetail.scss"
import {loadUserOrganisation, loadUserOrganisationGroup} from "../../data/organisations/organisations.actions";
import {connect} from "../../data/connect";
import Logger from "../../util/logger";
import DataContainer from "../components/DataContainer";
import {OrganisationGroup} from "../../data/organisations/domain/OrganisationGroup";
import {OrganisationGroupMember} from "../../data/organisations/domain/OrganisationGroupMember";
import ListingByAvatarTitleAndSubtitle from "../components/ListingByAvatarTitleAndSubtitle";
import {TEST_AVATAR} from "../../data/avatars";
import ErrorMessage from "../components/ErrorMessage";
import TabBar from "../components/TabBar";
import {Organisation, OrganisationId} from "../../data/organisations/domain/Organisation";
import ListingByName from "../components/ListingByName";

interface OrganisationGroupDetailProps extends RouteComponentProps<{ organisationId: string, organisationGroupId: string }> {
  loadUserOrganisationGroup: typeof loadUserOrganisationGroup;
  loadUserOrganisation: typeof loadUserOrganisation;
  selectedOrganisation: Organisation;
  selectedOrganisationGroup: OrganisationGroup;
  isOrganisationGroupLoading: boolean;
}

interface OrganisationGroupDetailState {
  memberSearchNeedle: string;
  roleSearchNeedle: string;
  responsiblesSearchNeedle: string;
  loadTriggered: boolean;
  activeView: 'members' | 'messages' | 'responsibles' | 'grouproles';
  organisationNotFoundError: boolean;
  organisationGroupNotFoundError: boolean;
}

const INITIAL_STATE: OrganisationGroupDetailState = {
  memberSearchNeedle: "",
  responsiblesSearchNeedle: "",
  roleSearchNeedle: "",
  loadTriggered: false,
  activeView: 'members',
  organisationNotFoundError: false,
  organisationGroupNotFoundError: false
}

class OrganisationGroupDetail extends Component<OrganisationGroupDetailProps, OrganisationGroupDetailState> {

  constructor(props: OrganisationGroupDetailProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  ionViewWillEnter() {
    const organisationId = this.props.match.params.organisationId;
    const organisationGroupId = this.props.match.params.organisationGroupId;
    if (this.state.loadTriggered) {
      Logger.info("user organisation group already loaded", organisationGroupId);
    } else {
      Logger.info("load user organisation group", organisationGroupId);
      this.props.loadUserOrganisation(organisationId, () => this.setState({...this.state, organisationNotFoundError: true}));
      this.props.loadUserOrganisationGroup(organisationId, organisationGroupId, () => this.setState({...this.state, organisationGroupNotFoundError: true}));
      this.setState({loadTriggered: true})
    }
  }

  ionViewWillLeave() {
    Logger.info("reset user organisationGroup load trigger");
    this.setState(INITIAL_STATE)
  }

  render() {
    const organisationId = this.props.match.params.organisationId;
    const organisationGroupId = this.props.match.params.organisationGroupId;
    const isLoading = this.props.isOrganisationGroupLoading || this.props.selectedOrganisationGroup === undefined
    const {selectedOrganisationGroup} = this.props;

    if (this.state.organisationNotFoundError) {
      return <ErrorMessage text={`Organisation with id ${this.props.match.params.organisationId} could not be found`}/>
    } else if (this.state.organisationGroupNotFoundError) {
      return <ErrorMessage
        text={`Organisation group with id ${this.props.match.params.organisationId}-${this.props.match.params.organisationGroupId} could not be found`}/>
    }

    return (
      <IonPage id="organisation-group-detail-page">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton/>
            </IonButtons>
            <IonTitle>{`Organisation Group Detail ${isLoading ? '' : `: ${selectedOrganisationGroup!.name}`}`}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {this.renderContent(isLoading, organisationId)}
        </IonContent>

        <TabBar
          items={
            [
              {value: "members", label: "Members", icon: peopleOutline, onClick: () => this.setState({activeView: 'members'})},
              {value: "messages", label: "Messages", icon: chatboxOutline, onClick: () => this.setState({activeView: 'messages'})},
              {value: "responsibles", label: "Responsibles", icon: ribbonOutline, onClick: () => this.setState({activeView: 'responsibles'})},
              {value: "grouproles", label: "group roles", icon: pricetagsOutline, onClick: () => this.setState({activeView: 'grouproles'})},
            ]
          }
          activeValue={this.state.activeView}
        />
      </IonPage>
    )
  }

  private renderContent(isLoading: boolean, organisationId: OrganisationId) {
    return (
      <DataContainer
        isLoading={isLoading}
        contentRender={() => {
          if (this.state.activeView === 'messages') {
            return this.renderMessagesContent();
          } else if (this.state.activeView === 'responsibles') {
            return this.renderResponsiblesContent();
          } else if (this.state.activeView === 'grouproles') {
            return this.renderGroupRolesContent(this.props.selectedOrganisationGroup, organisationId);
          } else {
            return this.renderMembersContent();
          }
        }}
      />
    )
  }

  private renderGroupRolesContent(organisationGroup: OrganisationGroup, organisationId: OrganisationId) {
    const roles = organisationGroup.availableMemberRoles;
    roles.setFilter(this.state.roleSearchNeedle);
    return (
      <div className="content-container">
        <ListingByName
          items={
            roles.data.map((role, index) => {
              return {
                name: role.name
              }
            })
          }
          onSearchNeedleChanged={newValue => this.setState({roleSearchNeedle: newValue})}
        />
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton>
            <IonIcon icon={addOutline}
                     onClick={() => this.props.history.push({
                       pathname: `/organisations/${organisationId}/groups/${organisationGroup.id}/grouproles/new`,
                       state: {organisationName: this.props.selectedOrganisation.name, organisationGroupName: this.props.selectedOrganisationGroup.name}
                     })}
            />
          </IonFabButton>
        </IonFab>
      </div>
    )
  }

  private renderResponsiblesContent() {
    const organisationId = this.props.match.params.organisationId;
    const organisationGroup = this.props.selectedOrganisationGroup;
    organisationGroup.members.clearFilter();
    const responsibles = organisationGroup.responsibles;
    responsibles.setNameFilter(this.state.responsiblesSearchNeedle);

    return (
      <div className="content-container">
        <ListingByAvatarTitleAndSubtitle
          items={
            responsibles.data.map((member, index) => {
              return {
                title: member.name,
                avatar: member.avatar,
                subtitle: undefined,
                onEdit: () => {
                  this.props.history.push(`/organisations/${organisationId}/groups/${organisationGroup.id}/responsibles/${member.id}/edit`);
                }
              }
            })
          }
          onSearchNeedleChanged={newValue => this.setState({responsiblesSearchNeedle: newValue})}
        />
      </div>
    )
  }


  private renderMembersContent() {
    const organisationId = this.props.match.params.organisationId;
    const organisationGroupId = this.props.match.params.organisationGroupId;
    const members = this.props.selectedOrganisationGroup.members;
    members.clearFilter();
    members.setNameFilter(this.state.memberSearchNeedle);

    return (
      <div className="content-container">
        <ListingByAvatarTitleAndSubtitle
          items={members.data.map((member) => {
            const item = member.toListingByAvatarTitleAndSubtitleItem(() => {
              this.props.history.push(`/organisations/${organisationId}/groups/${organisationGroupId}/members/${member.id}/edit`);
            });
            if(member.isResponsible){
              item.subtitle = `Group responsible, ${item.subtitle}`
            }
            return item
          })}
          onSearchNeedleChanged={newValue => this.setState({memberSearchNeedle: newValue})}
        />
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton>
            <IonIcon icon={pencilOutline}
                     onClick={() => this.props.history.push({
                       pathname: `/organisations/${organisationId}/groups/${organisationGroupId}/members/edit`
                     })}/>
          </IonFabButton>
        </IonFab>
      </div>
    )
  }

  private renderMessagesContent() {
    return (
      <div className="content-container">
        <IonList>
          <IonItem>
            <IonAvatar slot="start">
              <img
                src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                Net terug uit vakantie: splitsen we de standups of niet?.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <img
                src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                Dat zou ik ook wel willen weten.<br/>De laatste week was het alleszins wel het geval.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:44 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonCard slot="end">
              <IonCardContent>
                Onnozelaars, natuurlijk is het gesplitst.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
            <IonAvatar slot="end">
              <img
                src={TEST_AVATAR}/>
            </IonAvatar>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <img
                src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                ok, merci.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <img
                src={TEST_AVATAR}/>
            </IonAvatar>
            <IonCard>
              <IonCardContent>
                thx.
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:44 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonCard slot="end">
              <IonCardContent>
                Graag gedaan
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle><small>Maarten Vandeperre | 22:43 - 19 augustus</small></IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
            <IonAvatar slot="end">
              <img
                src={TEST_AVATAR}/>
            </IonAvatar>
          </IonItem>
          <IonItemDivider/>
          <IonItem>
            {/*<IonTextarea rows={6} cols={20} placeholder="Enter any notes here..." value={text} onIonChange={e => setText(e.detail.value!)}></IonTextarea>*/}
            <IonTextarea rows={3} cols={20} placeholder="Enter response...">&nbsp;</IonTextarea>
            <IonRow>
              <IonCol>
                <IonButton type="submit" expand="block">Submit</IonButton>
              </IonCol>
            </IonRow>
          </IonItem>
        </IonList>
      </div>
    )
  }
}

export default connect<OrganisationGroupDetailProps>({
  mapStateToProps: (state) => ({
    selectedOrganisation: state.organisationsState.selectedOrganisation,
    selectedOrganisationGroup: state.organisationsState.selectedOrganisationGroup,
    isOrganisationGroupLoading: state.organisationsState.isOrganisationGroupLoading,
  }),
  mapDispatchToProps: {
    loadUserOrganisation,
    loadUserOrganisationGroup,
  },
  component: withIonLifeCycle(OrganisationGroupDetail)
})
